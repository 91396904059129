import {
  createListenerMiddleware,
  isAnyOf,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";

import { LOCAL_STORAGE } from "@/constants";

import showToastMessages from "@/helpers/showToastMessages";

import { setCurrentlyAppliedCandidateSavedSearch } from "../slice/recruiterView/CandidateList/currentlyAppliedCandidateSavedSearchSlice";
import { RootState } from "../types";

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // console.warn("We got a rejected action!", action);
    showToastMessages({ response: { data: action.payload.data } });
  }

  return next(action);
};

export const listenerForLocalStorageMiddleware = createListenerMiddleware();
listenerForLocalStorageMiddleware.startListening({
  matcher: isAnyOf(setCurrentlyAppliedCandidateSavedSearch),
  effect: (action, listenerApi) => {
    localStorage.setItem(
      LOCAL_STORAGE.CURRENT_APPLIED_SAVED_FILTER_ID_IN_RECRUITER_VIEW,
      JSON.stringify(
        (listenerApi.getState() as RootState)
          .currentlyAppliedCandidateSavedSearchSlice?.id ?? null
      )
    );
  },
});
