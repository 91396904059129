import dayjs from "dayjs";
import pick from "lodash/pick";

import {
  CandidateFormType,
  CandidateType,
  CandidateUpdateType,
  ExperienceUpdateType,
} from "@/types/candidate";
import { PlatformType } from "@/types/utils";

import { LAKH_CURRENCY_MULTIPLIER } from "@/constants";
import { openToOffersOptions } from "@/constants/candidates";

export const transformCandidateDataToFormData = (
  candidateDetail: CandidateType
) => {
  const formData: CandidateFormType = { ...candidateDetail };

  formData.mobile__form_value = {
    dialCode: candidateDetail.mobile_code || "+91",
    phoneNumber: candidateDetail.mobile || "",
  };
  formData.alt_mobile__form_value = {
    dialCode: candidateDetail.alt_mobile_code || "+91",
    phoneNumber: candidateDetail.alt_mobile || "",
  };

  if (candidateDetail.experience_start_date) {
    const year = dayjs().diff(candidateDetail.experience_start_date, "year");

    const month =
      dayjs().diff(candidateDetail.experience_start_date, "month") - year * 12;

    const formattedYear = year > 0 ? year.toString() : "0";
    const formattedMonth = month > 0 ? month.toString() : "";

    const date = formattedYear + (formattedMonth ? "." + formattedMonth : "");

    formData.experience_start_date__form_value = date;
  } else {
    formData.experience_start_date__form_value = "";
  }

  const openToOffersSelected = openToOffersOptions.find(
    (item) => item.value === candidateDetail.open_to_offer
  );
  if (openToOffersSelected) {
    formData.open_to_offer__form_value = openToOffersSelected;
  }

  formData.current_ctc =
    candidateDetail.current_ctc / LAKH_CURRENCY_MULTIPLIER || undefined;

  formData.expected_ctc =
    candidateDetail.expected_ctc / LAKH_CURRENCY_MULTIPLIER || undefined;

  formData.offer_in_hand_ctc =
    candidateDetail.offer_in_hand_ctc / LAKH_CURRENCY_MULTIPLIER || undefined;

  formData.oih_joining_date = candidateDetail.oih_joining_date
    ? [candidateDetail.oih_joining_date]
    : [];

  if (candidateDetail.is_currently_working === true) {
    formData.is_currently_working = "true";
  } else if (candidateDetail.is_currently_working === false) {
    formData.is_currently_working = "false";
  } else formData.is_currently_working = undefined;

  switch (candidateDetail.resigned) {
    case true:
      formData.resigned__form_value = ["true"];
      break;
    case false:
      formData.resigned__form_value = [];
      break;
    default:
      formData.resigned__form_value = [];
  }

  formData.is_available_immediately__form_value =
    candidateDetail.is_available_immediately === true ? ["true"] : [];

  formData.candidate_join_date__form_value =
    candidateDetail?.candidate_join_date
      ? [candidateDetail.candidate_join_date]
      : [];

  formData.notice_period = candidateDetail.notice_period
    ? candidateDetail.notice_period
    : undefined;

  formData.last_working_day__form_value = candidateDetail.last_working_day
    ? [candidateDetail.last_working_day]
    : [];

  formData.buy_out = candidateDetail.buy_out || undefined;

  formData.location_preference_new__form_value =
    candidateDetail.location_preference_new ?? [];

  formData.job_role = candidateDetail.job_role || undefined;

  formData.candidate_primary_skills = candidateDetail.candidate_primary_skills
    ? candidateDetail.candidate_primary_skills.map((item) => {
        return {
          ...item,
          skill: item.skill,
          // proficiency_level: item.proficiency_level?.id
          //   ? item.proficiency_level?.id.toString()
          //   : "",
        };
      })
    : [];

  formData.other_skills = candidateDetail.other_skills || [];

  formData.links = candidateDetail.links;

  formData.educations =
    candidateDetail.educations?.map((education) => ({
      ...education,

      end_date__form_value: education.end_date
        ? [new Date(education.end_date)]
        : undefined,
    })) || [];

  formData.experiences =
    candidateDetail.experiences?.map((experience) => ({
      ...experience,
      job_title: experience.job_title ?? "",
      start_date__form_value: experience.start_date
        ? [new Date(experience.start_date)]
        : [],
      end_date__form_value: experience.end_date
        ? [new Date(experience.end_date)]
        : [],
    })) || [];

  // formData.desired_company_sizes_details =
  //   candidateDetail.desired_company_sizes_details || [];

  // formData.desired_company_stages_details =
  //   candidateDetail.desired_company_stages_details || [];

  if (formData.desired_company_types)
    formData.desired_company_types__form_value =
      candidateDetail.desired_company_types.map((item) =>
        JSON.stringify({ id: item.id, name: item.name })
      );
  else formData.desired_company_types__form_value = [];

  if (candidateDetail.is_dealbreaker) {
    formData.is_dealbreaker__form_values = ["true"];
  } else {
    formData.is_dealbreaker__form_values = [];
  }
  if (candidateDetail.desired_company_categories)
    formData.desired_company_categories__form_value =
      candidateDetail.desired_company_categories.map((item) =>
        JSON.stringify({ id: item.id, name: item.name })
      );
  else formData.desired_company_categories__form_value = [];

  if (candidateDetail.desired_company_sizes)
    formData.desired_company_sizes__form_value =
      candidateDetail.desired_company_sizes.map((item) => item.toString());
  else formData.desired_company_sizes__form_value = [];

  formData.negotiable_expected_ctc__form_value =
    candidateDetail.negotiable_expected_ctc === true ? ["true"] : [];

  formData.negotiable_notice_period__form_value =
    candidateDetail.negotiable_notice_period === true ? ["true"] : [];

  formData.current_work_type__form_value =
    candidateDetail.current_work_type?.length === 1
      ? candidateDetail.current_work_type[0]
      : undefined;

  if (formData.has_disability) {
    formData.disability__form_value = ["true"];
  } else {
    formData.disability__form_value = [];
  }

  if (formData.is_primary_whatsapp) {
    formData.number_which_is_whatsapp = "primary";
  } else if (formData.is_secondary_whatsapp) {
    formData.number_which_is_whatsapp = "secondary";
  } else formData.number_which_is_whatsapp = undefined;

  return formData;
};

export const transformFormDataToCandidateData = (
  formData: CandidateFormType,
  pickFields: (keyof CandidateUpdateType)[]
) => {
  const payload: CandidateUpdateType = {
    ...formData,
    id: formData.id || undefined,
    links:
      formData.links
        ?.filter((link: PlatformType) => link.url !== "")
        .map(
          (link: PlatformType) =>
            ({
              ...(link.id > 1 && { id: link.id }),
              url: link.url,
              platform: link.platform.id,
            } as { url: string; platform: number })
        ) || [],
  };

  if (formData.mobile__form_value?.phoneNumber !== undefined) {
    payload.mobile = formData.mobile__form_value?.phoneNumber;
    payload.mobile_code = formData.mobile__form_value?.dialCode;
  }

  if (formData.alt_mobile__form_value?.phoneNumber !== undefined) {
    payload.alt_mobile = formData.alt_mobile__form_value?.phoneNumber;
    payload.alt_mobile_code = formData.alt_mobile__form_value?.dialCode;
  }

  if (formData.experience_start_date__form_value) {
    const [year, month] = formData.experience_start_date__form_value
      .split(".")
      .map((item) => parseInt(item));

    if (year && month) {
      payload.experience_start_date = dayjs()
        .subtract(year, "year")
        .subtract(month, "month")
        .format("YYYY-MM-DD");
    } else if (year) {
      payload.experience_start_date = dayjs()
        .subtract(year, "year")
        .format("YYYY-MM-DD");
    } else if (month) {
      payload.experience_start_date = dayjs()
        .subtract(month, "month")
        .format("YYYY-MM-DD");
    }
  }

  if (formData.current_ctc)
    payload.current_ctc = Math.round(
      formData.current_ctc * LAKH_CURRENCY_MULTIPLIER
    );

  if (formData.expected_ctc)
    payload.expected_ctc = Math.round(
      formData.expected_ctc * LAKH_CURRENCY_MULTIPLIER
    );

  if (formData.offer_in_hand_ctc) {
    payload.offer_in_hand_ctc = Math.round(
      formData.offer_in_hand_ctc * LAKH_CURRENCY_MULTIPLIER
    );
  }

  if (formData.candidate_primary_skills) {
    payload.candidate_primary_skills = formData.candidate_primary_skills
      .filter((item) => typeof item.skill === "object")
      .map((item) => {
        return {
          ...item,
          skill: item.skill.id,
          // proficiency_level: item.proficiency_level,
        };
      });
  }
  if (formData.other_skills) {
    payload.other_skills = formData.other_skills;
  }

  if (formData.open_to_offer__form_value) {
    payload.open_to_offer = formData.open_to_offer__form_value.value;
  }

  if (formData.is_currently_working === "true") {
    payload.is_currently_working = true;

    if (formData.resigned__form_value?.includes("true")) {
      payload.resigned = true;

      if (
        formData.last_working_day__form_value &&
        formData.last_working_day__form_value?.length > 0
      ) {
        payload.last_working_day = formData.last_working_day__form_value[0];
      }

      delete payload.notice_period;
    } else {
      payload.resigned = false;
      delete payload.last_working_day;
    }
  } else if (formData.is_currently_working === "false") {
    payload.is_currently_working = false;

    payload.is_available_immediately =
      formData.is_available_immediately__form_value?.includes("true");
    if (
      payload.is_available_immediately === false &&
      formData.candidate_join_date__form_value &&
      formData.candidate_join_date__form_value.length > 0
    ) {
      payload.candidate_join_date = dayjs(
        formData.candidate_join_date__form_value[0]
      ).format("YYYY-MM-DD");
    } else {
      payload.candidate_join_date = undefined;
    }

    delete payload.resigned;
    delete payload.notice_period;
    delete payload.last_working_day;
  }

  payload.location_preference_new = [];
  //

  if (formData.location_preference_new__form_value) {
    //either add "Remote" / "Onsite"
    payload.location_preference_new =
      formData.location_preference_new__form_value;
  }

  //if "Remote" is added, then remove all the preferred city added. Also if work preference is null, then on patch add empty []
  if (
    formData.preferred_location_new === null ||
    (formData.location_preference_new__form_value?.length === 1 &&
      formData.location_preference_new__form_value.includes("Remote")) // if only remote is selected, then send empty list
  ) {
    payload.preferred_location_new = [];
  }

  if (formData.oih_joining_date && formData.oih_joining_date.length > 0) {
    payload.oih_joining_date = dayjs(formData.oih_joining_date[0]).format(
      "YYYY-MM-DD"
    );
  } else {
    payload.oih_joining_date = undefined;
  }

  if (formData.experiences) {
    payload.experiences = formData.experiences.map((experience) => {
      const formattedData: ExperienceUpdateType = {
        ...experience,
        start_date: undefined,
        end_date: undefined,
      };
      if (experience.company_detail) {
        formattedData.company = experience.company_detail.id;
      }

      if (experience.location_details) {
        formattedData.location = experience.location_details.id;
      }

      if (
        experience.start_date__form_value &&
        experience.start_date__form_value.length > 0
      ) {
        formattedData.start_date = dayjs(
          experience.start_date__form_value[0]
        ).format("YYYY-MM-DD");
      }

      if (
        !experience.is_currently_working &&
        experience.end_date__form_value &&
        experience.end_date__form_value.length > 0
      ) {
        formattedData.end_date = dayjs(
          experience.end_date__form_value[0]
        ).format("YYYY-MM-DD");
      }

      const pickedFields = pick(formattedData, [
        "id",
        "company",
        "description",
        "employment_type",
        "end_date",
        "is_currently_working",
        "job_title",
        "location",
        "start_date",
        "id",
      ]);

      return pickedFields;
    });
  }

  if (formData.educations) {
    payload.educations = formData.educations.map((education) => {
      const formattedEducation = {
        ...education,
        ...(education.degree_details && {
          degree: education.degree_details.id,
        }),
        ...(education.location_details && {
          location: education.location_details.id,
        }),
        ...(education.institution_details && {
          institution: education.institution_details.id,
        }),
        ...(education.specialization_details && {
          specialization: education.specialization_details.id,
        }),
        ...(education.cgpa && { cgpa: education.cgpa }),
        ...(education.end_date__form_value &&
          education.end_date__form_value.length > 0 && {
            end_date: dayjs(education.end_date__form_value[0]).format(
              "YYYY-MM-DD"
            ),
          }),
      };

      const pickedFields = pick(formattedEducation, [
        "id",
        "institution",
        "degree",
        "location",
        "end_date",
        "specialization",
        "cgpa",
      ]);

      return pickedFields;
    });
  }

  if (formData.desired_industries_details)
    payload.desired_industries = formData.desired_industries_details.map(
      (item) => item.id
    );

  if (formData.desired_company_stages_details)
    payload.desired_company_stages =
      formData.desired_company_stages_details.map((item) => item.id);

  if (formData.desired_company_sizes_details)
    payload.desired_company_sizes = formData.desired_company_sizes_details.map(
      (item) => item.id
    );

  if (formData.negotiable_notice_period__form_value) {
    payload.negotiable_notice_period =
      formData.negotiable_notice_period__form_value.includes("true");
  }

  if (formData.negotiable_expected_ctc__form_value) {
    payload.negotiable_expected_ctc =
      formData.negotiable_expected_ctc__form_value.includes("true");
  }

  if (formData.current_location === null) {
    payload.current_location = undefined;
  }

  if (formData.current_work_type__form_value) {
    payload.current_work_type = [formData.current_work_type__form_value];
  }

  if (formData.desired_company_types__form_value) {
    payload.desired_company_types =
      formData.desired_company_types__form_value.map((item) =>
        JSON.parse(item)
      );
  }
  if (formData.is_dealbreaker__form_values) {
    payload.is_dealbreaker =
      formData.is_dealbreaker__form_values.includes("true");
  }

  if (formData.desired_company_categories__form_value) {
    payload.desired_company_categories =
      formData.desired_company_categories__form_value.map((item) =>
        JSON.parse(item)
      );
  }

  if (formData.desired_company_sizes__form_value) {
    payload.desired_company_sizes =
      formData.desired_company_sizes__form_value.map((item) =>
        JSON.parse(item)
      );
  }

  if (formData.disability__form_value) {
    payload.has_disability = formData.disability__form_value.includes("true");
  }

  if (formData.candidate_other_parameter === null) {
    payload.candidate_other_parameter = undefined;
  }

  if (formData.number_which_is_whatsapp === "primary") {
    payload.is_primary_whatsapp = true;
    payload.is_secondary_whatsapp = false;
  } else if (formData.number_which_is_whatsapp === "secondary") {
    payload.is_secondary_whatsapp = true;
    payload.is_primary_whatsapp = false;
  }

  const pickedFields = pick(payload, ["id", ...pickFields]);

  return pickedFields;
};
