import { createAction, createSlice } from "@reduxjs/toolkit";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";

import { allTableColumnKeys } from "./tableColumns";

interface stateType {
  tableConfig: {
    columnVisibility: VisibilityState;
    columnOrder: ColumnOrderState;
    cutOffIndexForInvisibilityInColumnOrder: number;
  };
}

const initialState: stateType = {
  tableConfig: {
    columnOrder: allTableColumnKeys,
    columnVisibility: {
      client_status: false,
    },
    cutOffIndexForInvisibilityInColumnOrder: -1,
  },
};

export const setColumnVisibility = createAction<
  VisibilityState,
  "setColumnVisibility"
>("setColumnVisibility");

export const setColumnOrder = createAction<ColumnOrderState, "setColumnOrder">(
  "setColumnOrder",
);

const tableViewSlice = createSlice({
  name: "tableView",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setColumnVisibility, (state, { payload }) => {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          columnVisibility: {
            ...state.tableConfig.columnVisibility,
            ...payload,
          },
        },
      };
    });
    builder.addCase(setColumnOrder, (state, { payload }) => {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          columnOrder: payload,
        },
      };
    });
  },
});

export default tableViewSlice.reducer;
