const mixPanelEventName = {
  // nomenclature: ACTION_SUBJECT
  TIME_SPENT_BY_CLIENT_ON_CANDIDATE_PROFILE:
    "TimeSpentByClientOnCandidateProfile",
  //
  VIEW_PAGE: "NextJSViewPage",
  VIEW_QUESTIONS_FOR_A_PARAMETER: "ViewQuestionsForAParameter",

  //
  CLICK_ON_WATCH_ASSESSMENT: "ClickOnWatchAssessment",
  CLICK_ON_SWITCH_USER_ROLE_TO_CANDIDATE: "ClickOnSwitchUserRoleToCandidate",
  CLICK_ON_DOWNLOAD_RESUME: "ClickOnDownloadResume",
  CLICK_ON_UPDATE_PROFILE_BY_CANDIDATE: "ClickOnUpdateProfileByCandidate",

  //
  CHANGE_FILTER: "ChangeFilter",

  CHANGED_WEEKLY_SCHEDULE: "ChangedWeeklySchedule",
  MY_PROFILE_CLICK: "MyProfileClick",
  MY_PROFILE_EDIT_CLICK: "MyProfileEditClick",
  REQUEST_CANDIDATE_SLOTS_CLICK: "RequestCandidateSlotsClick",
  ADD_OFFER_CLICK: "AddOfferClick",
  ADD_NOTE_CLICK: "AddNoteClick",
  SORTING_CANDIDATES_SELECT: "SortingCandidatesSelect",
  INVITE_USER_CLICK: "InviteUserClick",
  INVITE_USER_SUCCESS: "InviteUserSuccess",
  ORGANIZATION_TAB_SELECT: "OrganizationTabSelect",
  RESEND_INVITE_CLICK: "ResendInviteClick",
  LOGIN: "Login",
  BUY_IN_TAKEN: "BuyInTaken",

  JOBS_CLICK: "JOBS_CLICK",
  REFER_AND_EARN_CLICK: "REFER_AND_EARN_CLICK",
  FOR_EMPLOYERS_CLICK: "FOR_EMPLOYERS_CLICK",
  LOGIN_CLICK: "LOGIN_CLICK",
  CREATE_YOUR_PROFILE_CLICK: "CREATE_YOUR_PROFILE_CLICK",
  CANDIDATE_STATUS_FOR_RECRUITER_CHANGE:
    "CANDIDATE_STATUS_FOR_RECRUITER_CHANGE",

  // CLIENT ACTIONS
  CANDIDATE_STATUS_CHANGE: "CANDIDATE_STATUS_CHANGE",
  EDIT_ORGANISATION_DETAILS: "EDIT_ORGANISATION_DETAILS",
};

export default mixPanelEventName;
