import { RhFormGroupItem, RhInputFormik, RhLabel } from "@rhythm-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import api from "@/services/api";

import { RootState } from "@/redux/types";

import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";

import { OpportunityContext } from "../constants";
import { setIsNotShortlistedInReviewDialogOpen } from "../stageAndStatusPopoverForCandidateOpportunitiesSlice";
import { FormSubmissionValues } from "../types";

type NotShortlistedInReviewDialogProps = {
  handleSubmit: (values: FormSubmissionValues) => void;
};

const NotShortlistedInReviewDialog = (
  props: NotShortlistedInReviewDialogProps,
) => {
  const opportunity = useContext(OpportunityContext);
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state: RootState) =>
      state.stageAndStatusPopoverForCandidateOpportunitiesSlice
        .isNotShortlistedInReviewDialogOpen,
  );

  const { data: candidateRejectReasons } = useQuery({
    queryKey: ["CANDIDATE_REJECT_REASONS"],
    queryFn: () => api.candidate.getCandidateRejectReasons(),
    select: (res) => res.data,
  });

  const handleSubmit = (values: FormSubmissionValues) => {
    // Handle form submission logic here
    props.handleSubmit(values);
    handleClose();
  };

  if (
    isOpen.id !== opportunity?.job_id ||
    isOpen.candidate_id !== opportunity.candidate_id
  )
    return null;

  const handleClose = () => {
    dispatch(
      setIsNotShortlistedInReviewDialogOpen({
        id: opportunity?.job_id,
        open: false,
        candidate_id: opportunity.candidate_id,
      }),
    );
  };

  return (
    <Dialog isOpen={isOpen.open} className="w-[600px]">
      <DialogHeader>
        <DialogTitle>Not Shortlisted</DialogTitle>
        <DialogDescription>
          Provide a reason for not shortlisting
        </DialogDescription>
      </DialogHeader>

      <Formik
        initialValues={{ detailed_reason: "" }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          reason: Yup.array(),
          detailed_reason: Yup.string().when("reason", {
            is: (reason: string[]) => !reason || reason.length === 0,
            then: Yup.string().required(
              "Please provide a reason for rejection. If not listed above, write it in textbox above",
            ),
          }),
        })}
      >
        <Form>
          <DialogBody>
            <RhLabel required>Select Reasons</RhLabel>
            {candidateRejectReasons?.map((item, idx) => (
              <div className="space-y-tpico" key={item.id}>
                <RhFormGroupItem
                  key={idx}
                  control={
                    <Field
                      className="m-tnano"
                      type="checkbox"
                      data-testid="reasons-chkbox"
                      name={`reason`}
                      value={item.id.toString()}
                    />
                  }
                  label={item.name}
                />
              </div>
            ))}
            <RhInputFormik
              name="detailed_reason"
              type={"textarea"}
              className="w-full"
              rows={4}
              autoFocus
              placeholder="Enter your comment"
            />
          </DialogBody>

          <DialogFooter>
            <Button variant={"tertiary"} onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogFooter>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default NotShortlistedInReviewDialog;
