import {
  BuyOutValuesEnum,
  CandidateFilterParamType,
  CandidateStatusType,
  LocationPreferenceEnum,
  OpenToOffersValuesEnum,
} from "@/types/candidate";

export const defaultFilters: CandidateFilterParamType = {
  ordering: "-created_ts",
  page: 1,
  offer_in_hand: "all",
  is_show_matched_candidates: false,
};

// candidate's current status
export const openToOffersValues: Record<string, OpenToOffersValuesEnum> = {
  Yes: "Yes",
  No: "No",
  Maybe: "Maybe",
};

export const openToOffersOptions: {
  value: OpenToOffersValuesEnum;
  label: string;
}[] = [
  { value: openToOffersValues.Yes, label: "Actively Looking" },
  { value: openToOffersValues.No, label: "Not Open to Offers" },
  { value: openToOffersValues.Maybe, label: "Open to Consider" },
];

// candidate's buyout
export const buyOutValues: Record<string, BuyOutValuesEnum> = {
  Yes: "Yes",
  No: "No",
  NotSure: "Not Sure",
};

export const buyOutOptions: { value: BuyOutValuesEnum; label: string }[] = [
  { value: buyOutValues.Yes, label: "Yes" },
  { value: buyOutValues.No, label: "No" },
  { value: buyOutValues.NotSure, label: "Not Sure" },
];

// candidate's location enum
export const locationPreferenceValues: Record<string, LocationPreferenceEnum> =
  {
    Hybrid: "Hybrid",
    Remote: "Remote",
    Onsite: "Onsite",
  };

export const locationPreferenceOptions: {
  value: LocationPreferenceEnum;
  label: string;
}[] = [
  { value: locationPreferenceValues.Remote, label: "Remote" },
  { value: locationPreferenceValues.Hybrid, label: "Hybrid" },
  { value: locationPreferenceValues.Onsite, label: "Onsite" },
];

export const candidateStageOptions: {
  value: CandidateStatusType;
  label: string;
  type?: "screening_stage" | "interview_stage" | "post_interview_stage";
}[] = [
  { value: "sourced", label: "Sourced", type: "screening_stage" },
  { value: "buyin_taken", label: "Buy-in Taken", type: "screening_stage" },
  { value: "screened", label: "Screened", type: "screening_stage" },
  { value: "did_not_pick", label: "Did Not Pick", type: "screening_stage" },
  { value: "call_later", label: "Call Later", type: "screening_stage" },

  { value: "to_schedule", label: "To Schedule", type: "interview_stage" },
  { value: "scheduled", label: "Scheduled", type: "interview_stage" },
  { value: "to_reschedule", label: "To Reschedule", type: "interview_stage" },
  { value: "cancelled", label: "Cancelled", type: "interview_stage" },
  { value: "consider_later", label: "Consider later", type: "interview_stage" },

  { value: "completed", label: "Completed", type: "post_interview_stage" },
  { value: "qualified", label: "Qualified", type: "post_interview_stage" },
  {
    value: "didnt_qualify",
    label: "Didn't Qualify",
    type: "post_interview_stage",
  },
  {
    value: "reject_candidate",
    label: "Rejected",
  },

  {
    value: "drop_candidate",
    label: "Dropped",
  },
];

export const candidateStatusScreeningStageOptions =
  candidateStageOptions.filter((item) => item.type === "screening_stage");

export const candidateStatusInterviewStageOptions =
  candidateStageOptions.filter((item) => item.type === "interview_stage");

export const candidateStatusPostInterviewStageOptions =
  candidateStageOptions.filter((item) => item.type === "post_interview_stage");
