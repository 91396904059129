import { createAction, createSlice } from "@reduxjs/toolkit";

export const setIsCustomizeTableDialogOpen = createAction<
  boolean,
  "setIsCustomizeTableDialogOpen"
>("setIsCustomizeTableDialogOpen");

const customizeTableViewForCandidateListDialogSlice = createSlice({
  name: "customizeTableViewForCandidateListDialog",
  initialState: false,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsCustomizeTableDialogOpen, (state, { payload }) => {
      return payload;
    });
  },
});

export default customizeTableViewForCandidateListDialogSlice.reducer;
