import { createAction, createSlice } from "@reduxjs/toolkit";

export const setCandidateResultCount = createAction<
  number,
  "setCandidateResultCount"
>("setCandidateResultCount");

const candidateSearchResultCountSlice = createSlice({
  name: "candidateSearchResultCount",
  initialState: 0,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setCandidateResultCount, (state, { payload }) => {
      return payload;
    });
  },
});

export default candidateSearchResultCountSlice.reducer;
