const formatCurrency = (number?: number | null) => {
  if (number === null || number === undefined) return "-";

  const formattedValue = (number / 100000).toFixed(1);
  return formattedValue.endsWith(".0")
    ? formattedValue.slice(0, -2) + " lpa"
    : formattedValue + " lpa";
};

export default formatCurrency;
