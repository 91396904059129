import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useCallback } from "react";

import api from "@/services/api";

import { CLIENT_STAGES } from "../constants";
import { THistoryItem } from "../types";

type THistoryItemProps = {
  item: THistoryItem;
};

const HistoryItem = (props: THistoryItemProps) => {
  const { data: candidateRejectReasons } = useQuery({
    queryKey: ["CANDIDATE_REJECT_REASONS"],
    queryFn: () => api.candidate.getCandidateRejectReasons(),
    select: (res) => res.data,
  });

  const getRejectReasonName = useCallback(
    (id: number) => {
      return (
        candidateRejectReasons?.find((reason) => reason.id === id)?.name ?? ""
      );
    },
    [candidateRejectReasons],
  );

  const getRejectReasons = useCallback(() => {
    const text = props.item.metadata?.reason?.map((reason) => {
      return getRejectReasonName(parseInt(reason));
    });

    if (text) {
      return text.join(", ");
    }
  }, [candidateRejectReasons, getRejectReasonName]);

  const getContent = useCallback(() => {
    if (props.item.stage === CLIENT_STAGES.INTERVIEWS) {
      return `${props.item.created_by} moved profile to ${props.item.stage}(${props.item.interview_round?.name}) - 
        ${props.item.status}`;
    }

    return `${props.item.created_by} moved profile to ${props.item.stage} -
        ${props.item.status}`;
  }, [props.item]);

  const formatDateTime = useCallback((timestamp: string) => {
    return dayjs(timestamp).format("D MMM YYYY, h:mma");
  }, []);

  return (
    <div className="py-2">
      <div>{getContent()}</div>
      {props.item.metadata && (
        <div className="bg-white p-2">
          <div>Reasons: {getRejectReasons()}</div>
          <div>Comment: {props.item.metadata.detailed_reason}</div>
        </div>
      )}

      <div className="text-slate-500">
        {formatDateTime(props.item.created_ts)}
      </div>
    </div>
  );
};

export default HistoryItem;
