import { OpenToOffersValuesEnum } from "@/types/candidate";

const getCandidateJobSearchStatus = (
  value: OpenToOffersValuesEnum | undefined
) => {
  if (value === "Yes") return "Actively Looking";
  else if (value === "Maybe") return "Open To Consider";
  else if (value === "No") return "Not Open To Offers";
  else return "-";
};

export default getCandidateJobSearchStatus;
