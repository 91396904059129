import { RhPopover, RhPopoverMenu, RhPopoverToggle } from "@rhythm-ui/react";
import React, { ReactNode, useState } from "react";

interface RootProps {
  children: ReactNode;
}

const Root: React.FC<RootProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <RhPopover
        isOpen={isOpen}
        placement="right-start"
        offset={[-12, 10]}
        className="bg-white"
      >
        <RhPopoverToggle asChild>
          <div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="relative"
          >
            <div className=" absolute left-[-7px] right-[-7px] top-[-7px] bottom-[-7px]"></div>
            {/* //element to still keep the menu open when in visinity of the targetarea */}
            {React.Children.map(children, (child) =>
              child &&
              React.isValidElement(child) &&
              child.type.displayName === "Target"
                ? child
                : null
            )}
          </div>
        </RhPopoverToggle>
        <RhPopoverMenu className=" bg-white">
          <div
            className="relative z-50 flex rounded-tpico cursor-default"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div className="bg-white shadow-[0_4px_8px_0px_rgba(0,0,0,0.24)] min-w-[250px] py-tmini flex flex-col gap-tpico rounded-sm">
              {React.Children.map(children, (child) =>
                child &&
                React.isValidElement(child) &&
                child.type.displayName === "Content"
                  ? child
                  : null
              )}
            </div>
          </div>
        </RhPopoverMenu>
      </RhPopover>
    </div>
  );
};

interface TargetProps {
  children: ReactNode;
}

const Target: React.FC<TargetProps> = ({ children }) => {
  return <>{children}</>;
};

Target.displayName = "Target";

interface ContentProps {
  children: ReactNode;
}

const Content: React.FC<ContentProps> = ({ children }) => {
  return <>{children}</>;
};

Content.displayName = "Content";

export const BoxTooltip = {
  Root,
  Target: Target,
  Content: Content,
};
