import mixpanel from "mixpanel-browser";

import { NODE_ENV } from "./constants";

const enableMixPanel =
  NODE_ENV === "production" ||
  process.env.NEXT_PUBLIC_ENABLE_MIXPANEL_ON_LOCAL === "true";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? "", {
  debug: !enableMixPanel,
  persistence: "localStorage",
});

export default mixpanel;
