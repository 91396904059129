import { queryClient } from "@/pages/_app";
import {
  RhDivider,
  RhIcon,
  RhInput,
  RhPopover,
  RhPopoverMenu,
  RhPopoverToggle,
} from "@rhythm-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { CandidateType, OpportunityUpdatePayload } from "@/types/candidate";

import api from "@/services/api";

import showToastMessages from "@/helpers/showToastMessages";

import { Button } from "@/components/ui/Button";

import CulturalDiscussionRejectDialog from "./components/CulturalDiscussionRejectDialog";
import CulturalDiscussionScheduledDialog from "./components/CulturalDiscussionScheduledDialog";
import DidNotJoinDialog from "./components/DidNotJoinDialog";
import DroppedInCulturalStageDialog from "./components/DroppedInCulturalStageDialog";
import DroppedInPracticalStageDialog from "./components/DroppedInPracticalStageDialog";
import DroppedInTechnicalStageDialog from "./components/DroppedInTechnicalStageDialog";
import JoinedDialog from "./components/JoinedDialog";
import NotShortlistedInReviewDialog from "./components/NotShortlistedInReviewDialog";
import OfferRejectedDialog from "./components/OfferRejectedDialog";
import PracticalDiscussionRejectDialog from "./components/PracticalDiscussionRejectDialog";
import PracticalDiscussionScheduledDialog from "./components/PracticalDiscussionScheduledDialog";
import TechnicalDiscussionRejectDialog from "./components/TechnicalDiscussionRejectDialog";
import TechnicalDiscussionScheduleDialog from "./components/TechnicalDiscussionScheduledDialog";
import {
  CLIENT_STAGES,
  CLIENT_STATUS,
  NEGATIVE_INTERVIEW_STATUS,
  OpportunityContext,
} from "./constants";
import {
  setIsCulturalDiscussionRejectDialogOpen,
  setIsCulturalDiscussionScheduledDialogOpen,
  setIsDidNotJoinDialogOpen,
  setIsDroppedInCulturalStageDialogOpen,
  setIsDroppedInPracticalStageDialogOpen,
  setIsDroppedInTechnicalStageDialogOpen,
  setIsJoinedDialogOpen,
  setIsNotShortlistedInReviewDialogOpen,
  setIsOfferRejectedDialogOpen,
  setIsPracticalDiscussionRejectdDialogOpen,
  setIsPracticalDiscussionScheduledDialogOpen,
  setIsTechnicalDiscussionRejectDialogOpen,
  setIsTechnicalDiscussionScheduledDialogOpen,
} from "./stageAndStatusPopoverForCandidateOpportunitiesSlice";
import OpportunitiesTabs from "./Tabs";
import {
  FormSubmissionValues,
  TOpportunityHistory,
  TStageValues,
} from "./types";
import {
  UnderscreeningOptions,
  UnderscreeningTrigger,
} from "./UnderscreeningTrigger";

type TOpportunityRef =
  | {
      status: string;
      stage: string;
      interviewRound?: number;
      metadata?: OpportunityUpdatePayload["metadata"];
      job_requirement: OpportunityUpdatePayload["job_requirement"];
    }
  | undefined;

const OpportunitiesTab = ({
  className,
  candidateDetail,
}: {
  className?: string;
  candidateDetail: CandidateType;
}) => {
  const { data: opportunities, isLoading } = useQuery({
    queryKey: [
      "CANDIDATES_JOB_STAGES",
      "ITEM",
      { candidateId: candidateDetail?.id },
    ],
    queryFn: () => api.candidate.getCandidateJobStagesById(candidateDetail?.id),
    select: (res) => res.data,
    enabled: !!candidateDetail?.id,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`${className} h-full`}>
      <OpportunitiesTabs
        opportunities={opportunities}
        candidate_id={candidateDetail.id}
      />
    </div>
  );
};

export default OpportunitiesTab;

export const StageAndStatusPopover = ({
  opportunity,
}: {
  opportunity: TOpportunityHistory;
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const opportunityRef = useRef<TOpportunityRef>();

  const updateOpportunityStageAndStatus = async () => {
    if (!opportunityRef.current) return;

    try {
      await api.candidate.patchCandidateJobStagesById({
        job_requirement: opportunityRef.current.job_requirement,
        stage: opportunityRef.current.stage,
        status: opportunityRef.current.status,
        candidate: opportunity.candidate,
        interview_round: opportunityRef.current.interviewRound,
        metadata: opportunityRef.current.metadata,
      });

      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATES_JOB_STAGES",
          "ITEM",
          { candidateId: opportunity.candidate },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "LIST"],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "JOB_REQUIREMENT_STATUS",
          { jobId: opportunity.job_info.job_id },
        ],
      });
    } catch (e) {
      showToastMessages(e);
    }
  };

  const setContext = (
    status: string,
    stage: string,
    final = true,
    interviewRound?: number,
    metadata?: OpportunityUpdatePayload["metadata"],
  ) => {
    setOpen(false);
    opportunityRef.current = {
      job_requirement: opportunity.job_info.job_id,
      status,
      stage,
      interviewRound,
      metadata,
    };

    if (final) {
      updateOpportunityStageAndStatus();
    }
  };

  const onFormSubmit = (values: FormSubmissionValues) => {
    if (!opportunityRef.current) return;

    opportunityRef.current = {
      ...opportunityRef.current,
      metadata: values,
    };

    updateOpportunityStageAndStatus();
  };

  const getInterviewRound = (stage: TStageValues, status: string) => {
    if (stage === CLIENT_STAGES.INTERVIEWS) {
      if (!NEGATIVE_INTERVIEW_STATUS.includes(status)) {
        return opportunity.current_round?.name;
      }
    }

    return `${stage}`;
  };

  return (
    <OpportunityContext.Provider
      value={{
        stage: opportunity.stage,
        status: opportunity.status,
        interview_id: opportunity.current_round?.id ?? null,
        job_id: opportunity.job_info.job_id,
        candidate_id: opportunity.candidate,
      }}
    >
      <div className="ml-auto">
        <RhPopover
          isOpen={open}
          onToggle={() => {
            setOpen(false);
          }}
        >
          {/* TRIGGER */}
          {!opportunity?.status && (
            <UnderscreeningTrigger
              onClick={() => {
                setOpen((state) => !state);
              }}
            />
          )}
          {!!opportunity?.status && (
            <RhPopoverToggle
              asChild
              onClick={() => {
                setOpen((state) => !state);
              }}
            >
              <Button className={`flex w-[260px]`} variant={"tertiary"}>
                <span className="flex w-full items-center justify-between truncate">
                  {getInterviewRound(opportunity.stage, opportunity.status)} -{" "}
                  {opportunity.status}
                </span>
                <RhIcon icon="tabler:chevron-down" className="" />
              </Button>
            </RhPopoverToggle>
          )}

          {/* MENU */}
          {!opportunity?.status && (
            <UnderscreeningOptions
              onOptionClick={() => {
                setOpen((state) => !state);
              }}
            />
          )}
          {/* TODO: Refactor this */}
          {!!opportunity?.status && (
            <RhPopoverMenu className="relative z-50">
              <div
                className="max-h-[60vh] w-[260px] overflow-y-auto rounded bg-white pt-tnano shadow-[0px_4px_8px_0px_rgba(0,0,0,0.24)]"
                data-testid="stages-dialog-box"
              >
                <StageTile>In Review</StageTile>
                <StatusButton
                  value="Profile Shortlisted"
                  onClick={() => setContext("Profile Shortlisted", "In Review")}
                >
                  Profile Shortlisted
                </StatusButton>
                <StatusButton
                  value="Profile On Hold"
                  onClick={() => setContext("Profile On Hold", "In Review")}
                >
                  Profile On Hold
                </StatusButton>
                <StatusButton
                  value="Not Shortlisted"
                  onClick={() => {
                    setOpen(false);
                    setContext("Not Shortlisted", "In Review", false);
                    dispatch(
                      setIsNotShortlistedInReviewDialogOpen({
                        id: opportunity.job_info.job_id,
                        open: true,
                        candidate_id: opportunity.candidate,
                      }),
                    );
                  }}
                >
                  Not Shortlisted
                </StatusButton>

                <RhDivider />
                <StageTile>In Interviews</StageTile>
                <div className="ml-4">
                  {opportunity.interview_rounds.map((interview_round, idx) => (
                    <React.Fragment key={idx}>
                      <StageTile>
                        {interview_round.interview_round__name}
                        {interview_round.interview_round__category__name && (
                          <>
                            {" "}
                            ({interview_round.interview_round__category__name})
                          </>
                        )}
                      </StageTile>
                      {interview_round.interview_round__category__name ===
                        "Practical Assessments" && (
                        <>
                          <StatusButton
                            value="To Send"
                            interview_id={interview_round.interview_round__id}
                            onClick={() =>
                              setContext(
                                "To Send",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              )
                            }
                          >
                            To Send
                          </StatusButton>
                          <StatusButton
                            value="Assignment Sent"
                            interview_id={interview_round.interview_round__id}
                            onClick={() => {
                              setContext(
                                CLIENT_STATUS["In Interviews"].PRACTICAL.SENT,
                                CLIENT_STAGES.INTERVIEWS,
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsPracticalDiscussionScheduledDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                          >
                            Assignment Sent
                          </StatusButton>
                          <StatusButton
                            value="Assignment Completed"
                            interview_id={interview_round.interview_round__id}
                            onClick={() =>
                              setContext(
                                "Assignment Completed",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              )
                            }
                          >
                            Assignment Completed
                          </StatusButton>
                          <StatusButton
                            value="Dropped"
                            interview_id={interview_round.interview_round__id}
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "Dropped",
                                "In Interviews",
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsDroppedInPracticalStageDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                          >
                            Dropped
                          </StatusButton>
                          <StatusButton
                            value="Rejected in Assignment"
                            interview_id={interview_round.interview_round__id}
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                CLIENT_STATUS["In Interviews"].PRACTICAL
                                  .REJECTED,
                                "In Interviews",
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsPracticalDiscussionRejectdDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                          >
                            Rejected in Assignment
                          </StatusButton>
                          <StatusButton
                            value="On Hold"
                            interview_id={interview_round.interview_round__id}
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "On Hold",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                          >
                            On Hold
                          </StatusButton>
                        </>
                      )}

                      {interview_round.interview_round__category__name ===
                        "Technical Rounds" && (
                        <>
                          <StatusButton
                            value="To Schedule"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "To Schedule",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            To Schedule
                          </StatusButton>
                          <StatusButton
                            value="Scheduled"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "Scheduled",
                                "In Interviews",
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsTechnicalDiscussionScheduledDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Scheduled
                          </StatusButton>
                          <StatusButton
                            value="Completed"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "Completed",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Completed
                          </StatusButton>
                          <StatusButton
                            value="Dropped"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "Dropped",
                                "In Interviews",
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsDroppedInTechnicalStageDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Dropped
                          </StatusButton>
                          <StatusButton
                            value="Rejected In Interviews"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "Rejected In Interviews",
                                "In Interviews",
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsTechnicalDiscussionRejectDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Rejected In Interviews
                          </StatusButton>
                          <StatusButton
                            value="On Hold"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "On Hold",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            On Hold
                          </StatusButton>
                        </>
                      )}

                      {interview_round.interview_round__category__name ===
                        "Cultural Rounds" && (
                        <>
                          <StatusButton
                            value="To Schedule"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "To Schedule",
                                "In Interviews",
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            To Schedule
                          </StatusButton>
                          <StatusButton
                            value="Scheduled"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                "Scheduled",
                                "In Interviews",
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsCulturalDiscussionScheduledDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Scheduled
                          </StatusButton>
                          <StatusButton
                            value="Completed"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                CLIENT_STATUS["In Interviews"].CULTURAL
                                  .COMPLETED,
                                CLIENT_STAGES.INTERVIEWS,
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Completed
                          </StatusButton>
                          <StatusButton
                            value="Dropped"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                CLIENT_STATUS["In Interviews"].CULTURAL.DROPPED,
                                CLIENT_STAGES.INTERVIEWS,
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsDroppedInCulturalStageDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Dropped
                          </StatusButton>
                          <StatusButton
                            value="Rejected In Interviews"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                CLIENT_STATUS["In Interviews"].CULTURAL
                                  .REJECTED,
                                CLIENT_STAGES.INTERVIEWS,
                                false,
                                interview_round.interview_round__id,
                              );
                              dispatch(
                                setIsCulturalDiscussionRejectDialogOpen({
                                  id: opportunity.job_info.job_id,
                                  open: true,
                                  candidate_id: opportunity.candidate,
                                }),
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            Rejected In Interviews
                          </StatusButton>
                          <StatusButton
                            value="On Hold"
                            onClick={() => {
                              setOpen(false);
                              setContext(
                                CLIENT_STATUS["In Interviews"].CULTURAL.ON_HOLD,
                                CLIENT_STAGES.INTERVIEWS,
                                true,
                                interview_round.interview_round__id,
                              );
                            }}
                            interview_id={interview_round.interview_round__id}
                          >
                            On Hold
                          </StatusButton>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>

                <RhDivider />

                <StageTile>In Offer Stage</StageTile>
                <StatusButton
                  value="In BGV"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Offers"].BGV,
                      CLIENT_STAGES.OFFERS,
                      true,
                    );
                  }}
                >
                  In BGV
                </StatusButton>
                <StatusButton
                  value="To Offer"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Offers"].TO_OFFER,
                      CLIENT_STAGES.OFFERS,
                      true,
                    );
                  }}
                >
                  To Offer
                </StatusButton>
                <StatusButton
                  value="Offered"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Offers"].OFFERED,
                      CLIENT_STAGES.OFFERS,
                      true,
                    );
                  }}
                >
                  Offered
                </StatusButton>
                <StatusButton
                  value="Offer Rejected"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Offers"].REJECTED,
                      CLIENT_STAGES.OFFERS,
                      false,
                    );
                    dispatch(
                      setIsOfferRejectedDialogOpen({
                        id: opportunity.job_info.job_id,
                        open: true,
                        candidate_id: opportunity.candidate,
                      }),
                    );
                  }}
                >
                  Offer Rejected
                </StatusButton>
                <StatusButton
                  value="Offer Accepted"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Offers"].ACCEPTED,
                      CLIENT_STAGES.OFFERS,
                      true,
                    );
                  }}
                >
                  Offer Accepted
                </StatusButton>

                <RhDivider />

                <StageTile>In Joining Stage</StageTile>
                <StatusButton
                  value="Joined"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Joining"].JOINED,
                      CLIENT_STAGES.JOINING,
                      false,
                    );
                    dispatch(
                      setIsJoinedDialogOpen({
                        id: opportunity.job_info.job_id,
                        open: true,
                        candidate_id: opportunity.candidate,
                      }),
                    );
                  }}
                >
                  Joined
                </StatusButton>
                <StatusButton
                  value="Did Not Join"
                  onClick={() => {
                    setOpen(false);
                    setContext(
                      CLIENT_STATUS["In Joining"].NOT_JOINED,
                      CLIENT_STAGES.JOINING,
                      false,
                    );
                    dispatch(
                      setIsDidNotJoinDialogOpen({
                        id: opportunity.job_info.job_id,
                        open: true,
                        candidate_id: opportunity.candidate,
                      }),
                    );
                  }}
                >
                  Did Not Join
                </StatusButton>
              </div>
            </RhPopoverMenu>
          )}
        </RhPopover>
      </div>

      <NotShortlistedInReviewDialog handleSubmit={onFormSubmit} />
      <OfferRejectedDialog handleSubmit={onFormSubmit} />
      <JoinedDialog handleSubmit={onFormSubmit} />
      <DidNotJoinDialog handleSubmit={onFormSubmit} />
      <DroppedInPracticalStageDialog handleSubmit={onFormSubmit} />
      <DroppedInTechnicalStageDialog handleSubmit={onFormSubmit} />
      <DroppedInCulturalStageDialog handleSubmit={onFormSubmit} />
      <CulturalDiscussionRejectDialog handleSubmit={onFormSubmit} />
      <CulturalDiscussionScheduledDialog handleSubmit={onFormSubmit} />
      <PracticalDiscussionRejectDialog handleSubmit={onFormSubmit} />
      <PracticalDiscussionScheduledDialog handleSubmit={onFormSubmit} />
      <TechnicalDiscussionRejectDialog handleSubmit={onFormSubmit} />
      <TechnicalDiscussionScheduleDialog handleSubmit={onFormSubmit} />
    </OpportunityContext.Provider>
  );
};

const StageTile = ({ children }: { children: React.ReactNode }) => (
  <div className="px-tsm py-tpico">
    <p className="body-small text-hint">{children}</p>
  </div>
);

const StatusButton = ({
  children,
  value,
  onClick,
  interview_id = null,
}: {
  children: React.ReactNode;
  value: string;
  interview_id?: number | null;
  onClick: () => void;
}) => {
  const opportunity = useContext(OpportunityContext);
  const isChecked = () => {
    if (opportunity?.stage === CLIENT_STAGES.INTERVIEWS) {
      return (
        opportunity?.status === value &&
        interview_id === opportunity.interview_id
      );
    }

    return opportunity?.status === value;
  };

  return (
    <div
      onClick={onClick}
      className="flex cursor-pointer items-center gap-tpico px-tsm py-tpico hover:bg-gray-100"
    >
      <RhInput type="radio" className="m-tnano" checked={isChecked()} />
      <p>{children}</p>
    </div>
  );
};
