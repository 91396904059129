import { createAction, createSlice } from "@reduxjs/toolkit";

type ConfirmCandidateJobMatchType = null | {
  targetCandidateId: number;
  targetJobId: number;
  confirmTitle: string;
  confirmMessage: string;
};

interface stateType {
  openConfirmCandidateJobMatchDialog: null | ConfirmCandidateJobMatchType;
}

const initialState: stateType = {
  openConfirmCandidateJobMatchDialog: null,
};

export const setOpenConfirmCandidateJobMatchDialog = createAction<
  ConfirmCandidateJobMatchType,
  "setOpenConfirmCandidateJobMatchDialog"
>("setOpenConfirmCandidateJobMatchDialog");

const confirmCandidateJobMatchSlice = createSlice({
  name: "confirmCandidateJobMatch",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      setOpenConfirmCandidateJobMatchDialog,
      (state, { payload }) => {
        return {
          ...state,
          openConfirmCandidateJobMatchDialog: payload,
        };
      }
    );
  },
});

export default confirmCandidateJobMatchSlice.reducer;
