import React, { useState } from "react";

import { Tabs, TabsList, TabsTrigger } from "@/components/ui/Tabs";

import AllJobs from "./AllJobs";
import MatchedJobs from "./MatchedJobs";
import { TOpportunityHistory } from "./types";

const TABS = ["Matched Jobs", "All Jobs"];

type TOpportunitiesTabsProps = {
  opportunities?: TOpportunityHistory[];
  candidate_id: number;
};

const OpportunitiesTabs = (props: TOpportunitiesTabsProps) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(TABS[0]);

  return (
    <>
      <Tabs
        value={currentActiveTab}
        onValueChange={(tab) => setCurrentActiveTab(tab)}
      >
        <TabsList className="h-full">
          {TABS.map((tab) => (
            <TabsTrigger value={tab} key={tab} className="h-full">
              {tab}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <div className="h-full overflow-auto">
        {currentActiveTab === "Matched Jobs" && (
          <MatchedJobs opportunities={props.opportunities} />
        )}
        {currentActiveTab === "All Jobs" && (
          <AllJobs candidate_id={props.candidate_id} />
        )}
      </div>
    </>
  );
};

export default OpportunitiesTabs;
