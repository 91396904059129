import { createAction, createSlice } from "@reduxjs/toolkit";

import { CandidateType } from "@/types/candidate";

type valueType = {
  note: string;
  edited: boolean;
  targetCandidate: CandidateType;
} | null;

export const setIsExpandedNoteDailogOpen = createAction<
  valueType,
  "setIsExpandedNoteDailogOpen"
>("setIsExpandedNoteDailogOpen");

const isExpandedNoteDailogOpenSlice = createSlice({
  name: "isExpandedNoteDailogOpen",
  initialState: null as valueType,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsExpandedNoteDailogOpen, (state, { payload }) => {
      return payload;
    });
  },
});

export default isExpandedNoteDailogOpenSlice.reducer;
