import { RhSkeletonRectangle } from "@rhythm-ui/react";
import React from "react";

import { CandidateType } from "@/types/candidate";

import { BoxTooltip } from "../utils/Boxtooltip";
import { Chip } from "../utils/Chip";

const PrimarySkillChipView = ({
  candidateDetail,
  isLoading,
  hideLabel = false,
  showTooltipOnOverflow = false,
}: {
  candidateDetail: CandidateType | undefined;
  isLoading?: boolean;
  hideLabel?: boolean;
  showTooltipOnOverflow?: boolean;
}) => {
  const overflowLimit = 2;

  return (
    <div>
      {!hideLabel && (
        <div className="flex gap-tpico items-center mb-tpico">
          <p className="text-hint ">Primary Skills</p>
          {/* <RhIcon icon="tabler:info-circle" className="h-tsm w-tsm text-hint" /> */}
        </div>
      )}

      {isLoading === true ? (
        <div className="flex items-center gap-tmini h-[20px]">
          <RhSkeletonRectangle className="!h-[26px] !w-[70px] !rounded" />
          <RhSkeletonRectangle className="!h-[26px] !w-[70px] !rounded" />
          <RhSkeletonRectangle className="!h-[26px] !w-[70px] !rounded" />
        </div>
      ) : (
        <>
          {candidateDetail?.candidate_primary_skills &&
          candidateDetail?.candidate_primary_skills?.length !== 0 ? (
            <div className="flex gap-tnano">
              {(showTooltipOnOverflow
                ? candidateDetail.candidate_primary_skills.slice(
                    0,
                    overflowLimit
                  )
                : candidateDetail.candidate_primary_skills
              ).map((item, idx) => (
                <Chip key={idx} size={"sm"}>
                  <p>{`${item.skill.name} ${
                    item.experience ? `(${item.experience} y)` : ""
                  }`}</p>
                </Chip>
              ))}

              {showTooltipOnOverflow &&
                candidateDetail.candidate_primary_skills &&
                candidateDetail.candidate_primary_skills.length >
                  overflowLimit && (
                  <BoxTooltip.Root>
                    <BoxTooltip.Target>
                      <Chip size={"sm"} className="text-hint">
                        {`+${
                          candidateDetail.candidate_primary_skills.length -
                          overflowLimit
                        }`}
                      </Chip>
                    </BoxTooltip.Target>

                    <BoxTooltip.Content>
                      <p className="py-tpico px-tsm text-hint">
                        Primary Skills
                      </p>
                      <div className="flex gap-tnano flex-wrap h-min px-tsm py-tpico">
                        {candidateDetail.candidate_primary_skills
                          .slice(overflowLimit)
                          .map((item, idx) => (
                            <Chip key={idx} size={"sm"}>
                              <p>{`${item.skill.name} ${
                                item.experience ? `(${item.experience} y)` : ""
                              }`}</p>
                            </Chip>
                          ))}
                      </div>
                    </BoxTooltip.Content>
                  </BoxTooltip.Root>
                )}
            </div>
          ) : (
            <>
              <span>-</span>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PrimarySkillChipView;
