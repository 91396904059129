const getGender = (gender: string | undefined) => {
  switch (gender) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    case "U":
      return "Unknown";
    case "O":
      return "Other";
    default:
      return "-";
  }
};
export default getGender;
