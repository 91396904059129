import { createAction, createSlice } from "@reduxjs/toolkit";

type valueType = number;

export const setJobIdForPrimaryReportSetterDialog = createAction<
  valueType,
  "setJobIdForPrimaryReportSetterDialog"
>("setJobIdForPrimaryReportSetterDialog");

const isPrimaryReportSetterDialogOpenSlice = createSlice({
  name: "jobIdForPrimaryReportSetterDialog",
  initialState: 0, // TODO: Fix type
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      setJobIdForPrimaryReportSetterDialog,
      (state, { payload }) => {
        return payload;
      },
    );
  },
});

export default isPrimaryReportSetterDialogOpenSlice.reducer;
