import { createAction, createSlice } from "@reduxjs/toolkit";

import { ZelevateReportType } from "@/types/candidate";

type selectPrimaryReportType = null | {
  targetCandidateId: number;
  reportToBeEdited?: ZelevateReportType;
  // below thing is to enable opening job match dialog box, after selecting primary report
  openMatchJobDialogWithParametersOnPrimaryReportSelect?: {
    targetCandidateId: number;
  };
};

interface stateType {
  openSelectPrimaryReportDialog: selectPrimaryReportType;
}

const initialState: stateType = {
  openSelectPrimaryReportDialog: null,
};

export const setOpenSelectPrimaryReportDialog = createAction<
  selectPrimaryReportType,
  "setOpenSelectPrimaryReportDialog"
>("setOpenSelectPrimaryReportDialog");

const selectPrimaryReportSlice = createSlice({
  name: "selectPrimaryReport",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setOpenSelectPrimaryReportDialog, (state, { payload }) => {
      return {
        ...state,
        openSelectPrimaryReportDialog: payload,
      };
    });
  },
});

export default selectPrimaryReportSlice.reducer;
