import { RhIcon, RhPopoverMenu, RhPopoverToggle } from "@rhythm-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import api from "@/services/api";

import { setBulkSelectedCandidates } from "@/redux/slice/recruiterView/CandidateList/bulkSelectedCandidatesSlice";
import { setIsPrimaryReportSetterDialogOpen } from "@/redux/slice/recruiterView/CandidateList/isPrimaryReportSetterDialogOpenSlice";
import { setJobIdForPrimaryReportSetterDialog } from "@/redux/slice/recruiterView/CandidateList/primaryReportSetterJobIdSlice";

import { Button } from "@/components/ui/Button";

import { OpportunityContext } from "./constants";

type TUnderScreeningProps = {
  onClick?: () => void;
};
type TUnderScreeningOptionsProps = {
  onOptionClick: () => void;
};

export const UnderscreeningTrigger = (props: TUnderScreeningProps) => {
  return (
    <RhPopoverToggle asChild onClick={props.onClick}>
      <Button className={`flex w-[260px]`} variant={"tertiary"}>
        <span className="flex w-full items-center justify-between truncate">
          Underscreening
        </span>
        <RhIcon icon="tabler:chevron-down" className="" />
      </Button>
    </RhPopoverToggle>
  );
};

export const UnderscreeningOptions = (props: TUnderScreeningOptionsProps) => {
  const opportunity = useContext(OpportunityContext);
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const deleteCandidateFromUnderscreeningStatus = useMutation({
    mutationFn: () => {
      if (!opportunity?.job_id || typeof opportunity?.job_id !== "number")
        return Promise.reject(new Error("job id not defined"));
      return api.recruiter.deleteBulkCandidateFromUnderscreeningStatus({
        candidate_ids: [opportunity.candidate_id],
        job_ids: [opportunity.job_id],
      });
    },

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "LIST"],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATES",
          "ITEM",
          { candidateId: opportunity?.candidate_id },
        ],
      });

      queryClient.invalidateQueries({
        queryKey: ["JOB_REQUIREMENT_STATUS", { jobId: opportunity?.job_id }],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATES_JOB_STAGES",
          "ITEM",
          { candidateId: opportunity?.candidate_id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATE_RELEVANT_JOBS",
          { candidateId: opportunity?.candidate_id },
        ],
      });
    },
  });

  const submitToEmployer = () => {
    if (!opportunity?.candidate_id) return;

    dispatch(setBulkSelectedCandidates([opportunity?.candidate_id]));
    dispatch(setIsPrimaryReportSetterDialogOpen(true));
    dispatch(setJobIdForPrimaryReportSetterDialog(opportunity.job_id));
  };

  const removeFromList = () => {
    deleteCandidateFromUnderscreeningStatus.mutate();
    props.onOptionClick();
  };

  return (
    <RhPopoverMenu
      className="relative z-50"
      disabled={deleteCandidateFromUnderscreeningStatus.isLoading}
    >
      <div
        className="max-h-[60vh] w-[260px] overflow-y-auto rounded bg-white pt-tnano shadow-[0px_4px_8px_0px_rgba(0,0,0,0.24)]"
        data-testid="stages-dialog-box"
      >
        <div
          onClick={submitToEmployer}
          className="flex cursor-pointer items-center gap-tpico p-2 hover:bg-gray-100"
        >
          <p>Submit to employer</p>
        </div>
        <div
          onClick={removeFromList}
          className="RhPopoverMenuItemDanger flex cursor-pointer items-center gap-tpico p-2 hover:bg-gray-100"
        >
          <p>Remove from list</p>
        </div>
      </div>
    </RhPopoverMenu>
  );
};
