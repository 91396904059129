import { useRouter } from "next/router";

import { useQuery } from "@tanstack/react-query";
import React from "react";

import { CandidateType } from "@/types/candidate";

import api from "@/services/api";

import { StageAndStatusPopover } from "../CandidateDetailsDrawer/DrawerTabs/OpportunitiesTab";

const OpportunitiesTab = ({
  candidateDetail,
}: {
  className?: string;
  candidateDetail: CandidateType;
}) => {
  const router = useRouter();
  const jobId = router.query.jobId;
  const { data: opportunities } = useQuery({
    queryKey: [
      "CANDIDATES_JOB_STAGES",
      "ITEM",
      { candidateId: candidateDetail?.id },
    ],
    queryFn: () => api.candidate.getCandidateJobStagesById(candidateDetail?.id),
    select: (res) => res.data,
    enabled: !!candidateDetail?.id,
  });

  const opportunity = opportunities?.find((opportunity) => {
    if (!jobId || Array.isArray(jobId)) return;

    return opportunity.job_info.job_id === parseInt(jobId);
  });

  if (!opportunity) {
    return null;
  }

  return <StageAndStatusPopover opportunity={opportunity} />;
};

export default OpportunitiesTab;
