import React, { createContext, useState } from "react";

interface DefaultValueType {
  isAuthenticated: boolean;
  isAuthenticatedChecked: boolean;
  roles: string[];
  zelAccess: string | null;
  zelRefresh: string | null;
  userId: number | null;
  candidateId?: number | null;
  interviewerId?: number | null;
  email: string;
  name: string;
}

const defaultValue: DefaultValueType = {
  isAuthenticated: false,
  isAuthenticatedChecked: false,
  roles: [],
  zelAccess: null,
  zelRefresh: null,
  userId: null,
  candidateId: null,
  interviewerId: null,
  email: "",
  name: "",
};
interface AuthContextType {
  auth: DefaultValueType;
  setAuth: React.Dispatch<React.SetStateAction<DefaultValueType>>;
}

export const AuthContext = createContext({} as AuthContextType);
interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState(defaultValue);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
