import { createAction, createSlice } from "@reduxjs/toolkit";

import { AssessmentType } from "@/types/assessment";

type ScheduleInterviewActionType = null | {
  targetCandidateId: number;
};

type RescheduleInterviewActionType = null | {
  targetCandidateId: number;
  targetInterview: AssessmentType;
};

interface stateType {
  isScheduleInterviewFor: ScheduleInterviewActionType;
  isRescheduleInterviewFor: RescheduleInterviewActionType;
}

const initialState: stateType = {
  isScheduleInterviewFor: null,
  isRescheduleInterviewFor: null,
};

export const setScheduleInterviewFor = createAction<
  ScheduleInterviewActionType,
  "setScheduleInterviewFor"
>("setScheduleInterviewFor");

export const setRescheduleInterviewFor = createAction<
  RescheduleInterviewActionType,
  "setRescheduleInterviewFor"
>("setRescheduleInterviewFor");

const candidateInterviewScheduleSlice = createSlice({
  name: "candidateInterviewSchedule",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setScheduleInterviewFor, (state, { payload }) => {
        return {
          ...state,
          isScheduleInterviewFor: payload,
          isRescheduleInterviewFor: null,
        };
      })
      .addCase(setRescheduleInterviewFor, (state, { payload }) => {
        return {
          ...state,
          isScheduleInterviewFor: null,
          isRescheduleInterviewFor: payload,
        };
      });
  },
});

export default candidateInterviewScheduleSlice.reducer;
