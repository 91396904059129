import { TOpportunityHistory } from "@/views/candidates/components/CandidateDetailsDrawer/DrawerTabs/OpportunitiesTab/types";

import {
  CandidateFilterParamType,
  CandidateType,
  CandidateUpdateType,
  NewCandidateType,
  OpportunityUpdatePayload,
} from "@/types/candidate";
import { SubmissionType } from "@/types/company";
import { RecruiterActivityLog } from "@/types/recruiter-activity-log";
import { UserType } from "@/types/user";
import { PaginatedResponse } from "@/types/utils";

import axiosInstance from "@/services/api/axiosInstance";

import { PARSER_URL, PARSER_V2_URL } from "@/constants";

const candidates = "candidates";
const candidateJobStages = `candidate-job-stages`;
const candidateJRMatcher = `candidate-jr-matcher`;
const candidateResumeUpload = "candidate-resume-upload";
const platforms = "platforms";
const activityLog = "activity-log";
const degree = "degree";
const specialization = `specialization`;
const institutions = "institutions";
const skills = "skills";
const proficiencyLevel = `proficiency-level`;

const candidateExperiences = "candidate-experiences";
const candidateEducations = "candidate-educations";
// const candidateSkills = "candidate-skill";
// const candidateLinks = "candidate-links";
const candidateResumes = "candidate-resumes";
const cities = "cities";
const candidateSubmissionStatus = "candidate-submission-status";
const candidateSubmissions = "candidate-submissions";
const candidateRejectReasons = "reject-reasons";
const candidateSubmissionRejectReasons = "candidate-submission-reject-reasons";
const candidateActivityLogs = "candidate-activity-logs";
const activityLogFieldtypes = `activity-log-fieldtypes`;
const activityLogUsers = `activity-log-users`;
const candidateLogNoteActivity = "note-activity";
const candidateLogReminderActivity = "schedule-reminder";
const candidateMessageTemplate = "whatsapp-email-generate-preview";
const candidateMessageTemplateSend = "send-whatsapp-and-email-activity";
const jobRequirementGeneratePreview =
  "job-requirement-template-generate-preview";

const reconsiderCandidateForJobRequirement = `reconsider-candidate-for-job-requirement`;

const companyType = `company-type`;
const companyCategory = `company-category`;
const desiredCompanySizes = `desired-company-sizes`;

// const candidateSubmissions = "candidate-submissions";
const saveLists = "save-lists";

// const bandView = 'bands';
const roles = "roles";

// const topics = 'topics';
// const candidateReport = 'candidate-report';
const score = "score";
// const activityLog = 'activity-log';

const candidateExistCheck = `/candidate/existance-check`;

// TODO: Should we move all recruiter role api's to recruiter

export default {
  getCandidates(params?: CandidateFilterParamType) {
    return axiosInstance.get<PaginatedResponse<CandidateType>>(
      `${candidates}/search/`,
      { params },
    );
  },
  postCandidate(data: NewCandidateType) {
    return axiosInstance.post(`${candidates}/`, data);
  },
  patchCandidate(data: CandidateUpdateType) {
    return axiosInstance.patch<CandidateType>(
      `${candidates}/${data.id}/`,
      data,
    );
  },
  getCandidateById(id: number) {
    return axiosInstance.get<CandidateType>(`${candidates}/search/${id}/`);
  },
  getCandidateJobStagesById(id: number) {
    return axiosInstance.get<TOpportunityHistory[]>(
      `${candidateJobStages}?candidate=${id}`,
    );
  },
  patchCandidateJobStagesById(payload: OpportunityUpdatePayload) {
    return axiosInstance.patch(`${candidateJobStages}/`, payload);
  },
  postCandidateSubmission(data: any) {
    return axiosInstance.post(`${candidateSubmissions}/`, data);
  },
  deleteCandidateSubmission(id: number) {
    return axiosInstance.delete(`${candidateSubmissions}/${id}/`);
  },

  candidateExistCheck(params: { email?: string; mobile?: string }) {
    return axiosInstance.get<CandidateType>(`${candidateExistCheck}`, {
      params,
    });
  },

  // -------save to list---------
  getSaveList(params?: any) {
    return axiosInstance.get(`${saveLists}/`, { params });
  },
  // patchRecruiterList(id: number, data: any) {
  //   return axiosInstance.patch(`${saveLists}/${id}/`, data);
  // },
  postSaveList(data: any) {
    return axiosInstance.post(`${saveLists}/`, data);
  },

  //-----------------------------------------------------------------------------

  getMatchedCandidates(params?: CandidateFilterParamType) {
    return axiosInstance.get<{
      count: number;
      results: {
        full_fit: CandidateType[];
        partial_fit: CandidateType[];
        no_fit: CandidateType[];
      };
      next: string | null;
      previous: string | null;
    }>(`${candidateJRMatcher}/`, { params });
  },
  //Candidate Resume Upload (endpoint: 'candidate-resume-upload/')

  uploadResume(data: any) {
    return axiosInstance.post(`${candidateResumeUpload}/`, data);
  },

  //-----------------------------------------------------------------------------

  //Resume Parser (PARSER_URL: 'https://parser.talpoint.in/')

  patchCandidateResume(
    data: { upload_status?: string; candidate?: number },
    id: number,
  ) {
    return axiosInstance.patch(`/${candidateResumes}/${id}/`, data);
  },
  deleteCandidateResume(id: number) {
    return axiosInstance.delete(`/${candidateResumes}/${id}`);
  },
  getResumeByCandidateId(candidateid: number) {
    return axiosInstance.get(`/${candidateResumes}/`, {
      params: { candidate: candidateid },
    });
  },
  getActivityLogsByCandidateId(candidateid: number) {
    return axiosInstance.get(`/${activityLog}/`, {
      params: { candidate_id: candidateid },
    });
  },
  //   getResumeCandidate(id) {
  //     return axiosInstance.get(`/${candidateResumes}/${id}/`);
  //   },
  getResumeDetailsFromParser(
    data: {
      resume_url: string;
      basic_info?: boolean;
      education?: boolean;
      experience?: boolean;
    },
    controller?: AbortController,
  ) {
    return axiosInstance.post(`${PARSER_URL}/${score}/`, data, {
      signal: controller?.signal,
    });
  },
  getResumeDetailsFromParserV2(
    data: {
      resume_url: string;
      basic_info?: boolean;
      education?: boolean;
      experience?: boolean;
    },
    controller?: AbortController,
  ) {
    return axiosInstance.post(`${PARSER_V2_URL}/${score}/`, data, {
      signal: controller?.signal,
    });
  },

  //-----------------------------------------------------------------------------

  //Candidate Submission Api (endpoint: 'candidate-subimission//')

  //   postCandidateSubmission(data) {
  //     return axiosInstance.post(`${candidateSubmission}/`, data);
  //   },

  //-----------------------------------------------------------------------------

  // Platform Api (endpoint: 'platforms/')

  getPlatforms(params?: { search?: string }) {
    return axiosInstance.get(`${platforms}/`, { params });
  },

  //-----------------------------------------------------------------------------

  //Skills Api (endpoint: 'skills/')

  getDegree(params: { search: string }) {
    return axiosInstance.get(`${degree}/`, { params });
  },
  getSpecialization(params: { search: string }) {
    return axiosInstance.get(`${specialization}/`, { params });
  },
  getInstitution(params: { search: string }) {
    return axiosInstance.get(`${institutions}/`, { params });
  },
  postInstitution(data: { name: string }) {
    return axiosInstance.post(`${institutions}/`, data);
  },
  getSkillByRole(roleId: number) {
    return axiosInstance.get(`${skills}/?role=${roleId}`);
  },
  //-----------------------------------------------------------------------------

  deleteCandidateExperienceById(id: number) {
    return axiosInstance.delete(`${candidateExperiences}/${id}`);
  },

  deleteCandidateEducationById(id: number) {
    return axiosInstance.delete(`${candidateEducations}/${id}`);
  },

  //Roles Api (endpoint: 'roles/')

  getRoles(params?: { search?: string }) {
    return axiosInstance.get<PaginatedResponse<{ id: number; name: string }>>(
      `${roles}/`,
      { params },
    );
  },

  getCities(params?: { search?: string }) {
    return axiosInstance.get(`${cities}/`, { params });
  },

  getCandidateSubmissionEnum() {
    return axiosInstance.get(`${candidateSubmissionStatus}/`);
  },

  patchCandidateSubmission(
    id: number,
    params: { status: number; interview_round?: number },
  ) {
    return axiosInstance.patch<SubmissionType>(
      `${candidateSubmissions}/${id}/`,
      params,
    );
  },

  postReconsiderCandidate(payload: {
    job_requirement_id: number;
    candidate_id: number;
  }) {
    return axiosInstance.post(
      `${reconsiderCandidateForJobRequirement}/`,
      payload,
    );
  },

  getCandidateRejectReasons() {
    return axiosInstance.get<{ name: string; id: number }[]>(
      `${candidateRejectReasons}/`,
    );
  },
  postCandidateRejectReason(payload: {
    submission: number;
    reasons: number[];
    other_reason: string;
  }) {
    return axiosInstance.post(`${candidateSubmissionRejectReasons}/`, payload);
  },

  getSkills(data: { search?: string } = {}) {
    return axiosInstance.get(`${skills}/`, { params: data });
  },

  getProficienyLevel() {
    return axiosInstance.get(`${proficiencyLevel}/?pagination=false`);
  },

  // ___________CANDIDATE ACTIVITY [RECRUITER VIEW]__________

  getCandidateActivity(params: { candidate_id: number; page: number }) {
    return axiosInstance.get<PaginatedResponse<RecruiterActivityLog>>(
      `${candidateActivityLogs}/`,
      { params },
    );
  },

  getActivityLogFieldType() {
    return axiosInstance.get<{ id: number; name: string; label: string }[]>(
      `${activityLogFieldtypes}/?pagination=false`,
    );
  },

  getActivityLogActorsByCandidateId(candidate_id: number) {
    return axiosInstance.get<UserType[]>(`${activityLogUsers}/${candidate_id}`);
  },

  postCandidateActivityNote({
    candidateId,
    note,
  }: {
    candidateId: number;
    note: string;
  }) {
    return axiosInstance.post(`${candidateLogNoteActivity}/`, {
      candidate_id: candidateId,
      note,
    });
  },

  patchCandidateActivityNote({ id, note }: { id: number; note: string }) {
    return axiosInstance.patch(`${candidateLogNoteActivity}/${id}/`, {
      note,
    });
  },

  postCandidateActivityReminder({
    candidateId,
    reminder_datetime,
    reminder,
  }: {
    candidateId: number;
    reminder_datetime: string;
    reminder: string;
  }) {
    return axiosInstance.post(`${candidateLogReminderActivity}/`, {
      candidate_id: candidateId,
      reminder_datetime,
      reminder,
    });
  },

  patchCandidateActivityReminder({
    id,
    reminder_datetime,
    reminder,
  }: {
    id: number;
    reminder_datetime: string;
    reminder: string;
  }) {
    return axiosInstance.patch(`${candidateLogReminderActivity}/${id}/`, {
      reminder_datetime,
      reminder,
    });
  },

  getCandidateMessageTemplates(params: { candidate_id: number }) {
    return axiosInstance.get(`${candidateMessageTemplate}/`, { params });
  },
  postSendCandidateMessageTemplate({
    candidateId,
    template_id,
    email_send,
    whatsapp_send,
    job_requirement_ids,
  }: {
    candidateId: number;
    template_id: number;
    email_send: boolean;
    whatsapp_send: boolean;
    job_requirement_ids?: number[];
  }) {
    return axiosInstance.post(`${candidateMessageTemplateSend}/`, {
      candidate_id: candidateId,
      template_id,
      email_send,
      whatsapp_send,
      job_requirement_ids,
    });
  },

  getJobRequirementPreviewTemplates(params: {
    candidate_id: number;
    job_requirement_ids: string;
    template_id: number;
  }) {
    return axiosInstance.get(`${jobRequirementGeneratePreview}/`, { params });
  },

  getCompanyType() {
    return axiosInstance.get<{ id: number; name: string }[]>(`${companyType}/`);
  },

  getCompanyCategory() {
    return axiosInstance.get<{ id: number; name: string }[]>(
      `${companyCategory}/`,
    );
  },

  getDesiredCompanySizes() {
    return axiosInstance.get<{ id: number; name: string; label: string }[]>(
      `${desiredCompanySizes}/`,
    );
  },
};
