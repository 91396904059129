import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/Accordion";

import HistoryItem from "./components/HistoryItem";
import { THistoryItem } from "./types";

type THistoryProps = {
  history: THistoryItem[];
};

const History = (props: THistoryProps) => {
  const { history } = props;

  if (history.length === 1) {
    return <HistoryItem item={props.history[0]} />;
  }

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger className="items-stretch hover:no-underline">
          {props.history.slice(0, 1).map((data) => {
            return <HistoryItem key={data.id} item={data} />;
          })}
        </AccordionTrigger>
        <AccordionContent>
          {props.history.slice(1).map((data) => {
            return <HistoryItem key={data.id} item={data} />;
          })}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default History;
