import { getCookies } from "cookies-next";

import MixPanel from "@/MixPanel";
import { JwtPayload } from "jsonwebtoken";
import jwtDecode from "jwt-decode";
import { useContext } from "react";

import { AuthContext } from "@/context/AuthContext";

import deleteSiteCookiesOnClient from "@/helpers/deleteSiteCookiesOnSite";

const useAuth = () => {
  const { setAuth } = useContext(AuthContext);

  const setUserAuth = () => {
    const { roles: rolesAsString, zelAccess, zelRefresh } = getCookies();

    if (rolesAsString && zelAccess && zelRefresh) {
      try {
        const {
          user_id,
          Candidate_id,
          Interviewer_profile_id,
          email,
          name,
          user_company,
        } = jwtDecode(zelAccess) as JwtPayload;

        const roles = decodeURIComponent(rolesAsString).split("|");

        setAuth({
          isAuthenticated: true,
          isAuthenticatedChecked: true,
          roles,
          zelAccess,
          zelRefresh,
          userId: user_id,
          ...(Candidate_id && {
            candidateId: Candidate_id,
          }),
          ...(Interviewer_profile_id && {
            interviewerId: Interviewer_profile_id,
          }),
          ...(email && {
            email,
          }),
          ...(name && {
            name,
          }),
        });
        MixPanel.identify(user_id);
        MixPanel.register({
          userRoles: roles,
          userEmail: email,
          origin: "ZELEVATE PORTAL",
          userCompany: user_company,
        });
      } catch (error) {
        console.error(error);
        // POSTHOG error
      }
    } else {
      setAuth((state) => ({ ...state, isAuthenticatedChecked: true }));
    }
  };

  const signoutUser = () => {
    deleteSiteCookiesOnClient();
    setAuth({
      isAuthenticated: false,
      isAuthenticatedChecked: true,
      roles: [],
      userId: null,
      zelAccess: null,
      zelRefresh: null,
      candidateId: null,
      interviewerId: null,
      name: "",
      email: "",
    });
  };

  return { setUserAuth, signoutUser };
};

export default useAuth;
