import { RhDivider } from "@rhythm-ui/react";
import classNames from "classnames";
import React from "react";

import { StageAndStatusPopover } from ".";
import OpportunityHistory from "./History";
import { TOpportunityHistory } from "./types";

type MatchedJobsProps = {
  opportunities?: TOpportunityHistory[];
};

const MatchedJobs = (props: MatchedJobsProps) => {
  if (!props.opportunities?.length) {
    return <div>No matched jobs</div>;
  }

  return (
    <>
      {props.opportunities?.map((opportunity) => (
        <React.Fragment key={opportunity.id}>
          <div className="flex items-center gap-3 px-4 py-3">
            <img
              className="h-16 w-16 object-contain p-3"
              src={opportunity.job_info.company_logo}
              alt={opportunity.job_info.company_name}
            />

            <div className="flex flex-col">
              <h5 className="text-base">{opportunity.job_info.job_title}</h5>
              <p className="text-hint">{opportunity.job_info.company_name}</p>
            </div>

            <StageAndStatusPopover opportunity={opportunity} />
          </div>

          <div
            className={classNames({
              "bg-blue-50 p-2": !!opportunity.history.length,
            })}
          >
            {!!opportunity.history.length && (
              <OpportunityHistory history={opportunity.history} />
            )}
          </div>
          <div className="py-2">
            <RhDivider></RhDivider>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default MatchedJobs;
