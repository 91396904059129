import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const chipVariants = cva(
  "inline-flex items-center rounded border-[1px] text-[14px] font-normal leading-5 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-gray-300 bg-white ",
        blueFilled: "border-primary-300 bg-primary-50 text-primary-600",
      },
      size: {
        base: "py-[6px] px-[12px]",
        sm: "py-[2px] px-[8px]",
      },
      shape: {
        default: "rounded",
        circle: "rounded-[32px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "base",
      shape: "default",
    },
  },
);

export interface ChipProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chipVariants> {
  ref?: React.Ref<HTMLDivElement>; // Added ref type
}

const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  ({ className, variant, size, shape, ...props }, ref) => {
    return (
      <div
        ref={ref} // Forwarding ref
        className={cn(chipVariants({ variant, size, shape }), className)}
        {...props}
      />
    );
  },
);

Chip.displayName = "Chip"; // Setting display name for better debugging

export { Chip };
