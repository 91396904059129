import { createAction, createSlice } from "@reduxjs/toolkit";

export const setIsSavedSearchListDrawerOpen = createAction<
  boolean,
  "setIsSavedSearchListDrawerOpen"
>("setIsSavedSearchListDrawerOpen");

const isSavedSearchListDrawerOpenSlice = createSlice({
  name: "isSavedSearchListDrawerOpen",
  initialState: false,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsSavedSearchListDrawerOpen, (state, { payload }) => {
      return payload;
    });
  },
});

export default isSavedSearchListDrawerOpenSlice.reducer;
