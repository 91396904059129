import { createAction, createSlice } from "@reduxjs/toolkit";

import { CandidateType } from "@/types/candidate";

type RejectCandidateActionType = null | {
  candidate: CandidateType;
  jobId: number;
};

type MoveCandidteToPreviousStageActionType = null | {
  candidate: CandidateType;
  jobId: number;
  statusId: number;
  interview_round?: number;
};

type ReconsiderCandidateActionType = null | {
  candidate: CandidateType;
  jobId: number;
};

interface stateType {
  isRejectCandidateDialogOpen: RejectCandidateActionType;
  isMoveCandidateToPreviousStageDialogOpen: MoveCandidteToPreviousStageActionType;
  isReconsiderCandidateDialogOpen: ReconsiderCandidateActionType;
}

const initialState: stateType = {
  isRejectCandidateDialogOpen: null,
  isMoveCandidateToPreviousStageDialogOpen: null,
  isReconsiderCandidateDialogOpen: null,
};

export const setIsRejectCandidateDialogOpen = createAction<
  RejectCandidateActionType,
  "setIsRejectCandidateDialogOpen"
>("setIsRejectCandidateDialogOpen");

export const setIsMoveCandidateToPreviousStageDialogOpen = createAction<
  MoveCandidteToPreviousStageActionType,
  "setIsMoveCandidateToPreviousStageDialogOpen"
>("setIsMoveCandidateToPreviousStageDialogOpen");

export const setIsReconsiderCandidateDialogOpen = createAction<
  ReconsiderCandidateActionType,
  "setIsReconsiderCandidateDialogOpen"
>("setIsReconsiderCandidateDialogOpen");

const candidateStatusPopoverSlice = createSlice({
  name: "candidateStatusPopover",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setIsRejectCandidateDialogOpen, (state, { payload }) => {
        return {
          ...state,
          isRejectCandidateDialogOpen: payload,
        };
      })
      .addCase(
        setIsMoveCandidateToPreviousStageDialogOpen,
        (state, { payload }) => {
          return {
            ...state,
            isMoveCandidateToPreviousStageDialogOpen: payload,
          };
        }
      )
      .addCase(setIsReconsiderCandidateDialogOpen, (state, { payload }) => {
        return {
          ...state,
          isReconsiderCandidateDialogOpen: payload,
        };
      });
  },
});

export default candidateStatusPopoverSlice.reducer;
