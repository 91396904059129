import { createAction, createSlice } from "@reduxjs/toolkit";

import { CandidateType } from "@/types/candidate";

type isCandidateRejectDialogOpenType = null | {
  targetCandidate: CandidateType;
};

type isCandidateDropDialogOpenType = null | {
  targetCandidate: CandidateType;
};

type isCandidateReconsiderStatusDialogOpenType = null | {
  targetCandidate: CandidateType;
};

type valueType = {
  isCandidateRejectDialogOpen: isCandidateRejectDialogOpenType;
  isCandidateDropDialogOpen: isCandidateDropDialogOpenType;
  isCandidateReconsiderStatusDialogOpen: isCandidateReconsiderStatusDialogOpenType;
};

const initialState: valueType = {
  isCandidateRejectDialogOpen: null,
  isCandidateDropDialogOpen: null,
  isCandidateReconsiderStatusDialogOpen: null,
};

export const setIsCandidateRejectDialogOpen = createAction<
  isCandidateRejectDialogOpenType,
  "setIsCandidateRejectDialogOpen"
>("setIsCandidateRejectDialogOpen");

export const setIsCandidateDropDialogOpen = createAction<
  isCandidateDropDialogOpenType,
  "setIsCandidateDropDialogOpen"
>("setIsCandidateDropDialogOpen");

export const setIsCandidateReconsiderStatusDialogOpen = createAction<
  isCandidateReconsiderStatusDialogOpenType,
  "setIsCandidateReconsiderStatusDialogOpen"
>("setIsCandidateReconsiderStatusDialogOpen");

const candidateStatusTogglerSlice = createSlice({
  name: "candidateStatusToggler",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsCandidateRejectDialogOpen, (state, { payload }) => {
      state.isCandidateRejectDialogOpen = payload;
      return state;
    });

    builder.addCase(setIsCandidateDropDialogOpen, (state, { payload }) => {
      state.isCandidateDropDialogOpen = payload;
      return state;
    });

    builder.addCase(
      setIsCandidateReconsiderStatusDialogOpen,
      (state, { payload }) => {
        state.isCandidateReconsiderStatusDialogOpen = payload;
        return state;
      }
    );
  },
});

export default candidateStatusTogglerSlice.reducer;
