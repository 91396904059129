import { cn } from "@/lib/utils";
import * as React from "react";
import Modal from "react-modal";

const Dialog = ({
  isOpen,
  children,
  className,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className={cn(
        "absolute bg-white overflow-auto rounded outline-none top-[50%] left-[50%] max-h-[90vh] right-auto bottom-auto translate-x-[-50%] translate-y-[-50%] p-tsm w-[600px]",
        className
      )}
      style={{
        overlay: {
          background: "rgba(0 0 0 / 0.5)",
          zIndex: 10,
        },
      }}
    >
      {children}
    </Modal>
  );
};
Dialog.displayName = "Dialog";

const DialogBody = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn(className)} {...props}>
    {children}
  </div>
);
DialogBody.displayName = "DialogBody";

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-tpico text-center sm:text-left mb-tsm",
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 mt-tm2",
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithoutRef<"h3">
>(({ className, ...props }, ref) => (
  <h3 ref={ref} className={cn(className)} {...props} />
));
DialogTitle.displayName = "DialogTitle";

const DialogDescription = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<"p">
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn("text-gray-700", className)} {...props} />
));
DialogDescription.displayName = "DialogDescription";

export {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
