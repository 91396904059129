import { createAction, createSlice } from "@reduxjs/toolkit";

type ActionType = null | string;

interface stateType {
  action: string | null;
}

const initialState: stateType = {
  action: null,
};

export const setIsCreateActivity = createAction<
  ActionType,
  "setIsCreateActivity"
>("setIsCreateActivity");

const recruiterActionOnCandidateProfileSlice = createSlice({
  name: "recruiterActionOnCandidateProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsCreateActivity, (state, { payload }) => {
      return {
        ...state,
        action: payload,
      };
    });
  },
});

export default recruiterActionOnCandidateProfileSlice.reducer;
