import { RhIcon, RhSelect, RhToast, RhTooltip } from "@rhythm-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";

import {
  CandidateFormType,
  CandidateTagType,
  CandidateType,
} from "@/types/candidate";
import {
  CandidateZelevateAssessedType,
  CityType,
  SkillType,
} from "@/types/utils";

import api from "@/services/api";
import { transformFormDataToCandidateData } from "@/services/api/dataTransformers/candidateModel";

import { setIsExpandedNoteDailogOpen } from "@/redux/slice/recruiterView/CandidateList/isExpandedNoteDailogOpenSlice";

import formatCurrency from "@/helpers/formatCurrency";
import formatExperienceFromStartDate from "@/helpers/formatExperienceFromStartDate";
import getCandidateJoinStatus from "@/helpers/getCandidateJoinStatus";
import getGender from "@/helpers/getGender";

import PrimarySkillChipView from "@/components/CandidateDataView/PrimarySkillChipView";
import { BoxTooltip } from "@/components/utils/Boxtooltip";
import { Chip } from "@/components/utils/Chip";

import CandidateJobStatusSearchPopover from "../components/CandidateDetailsDrawer/CandidateJobStatusSearchPopover";
import CandidateStatusToggler from "../components/CandidateStatusToggler";
import ClientStatusToggler from "../components/ClientStatusToggler";

// TODO: Change to a different type
export const columnHelper = createColumnHelper<CandidateType>();

const defaultColumn = [
  columnHelper.accessor("name", {
    header: () => <h5 className="text-start">Full Name</h5>,
    meta: {
      name: "Full Name",
    },
  }),
  columnHelper.accessor("client_status", {
    header: () => <h5 className="text-start">Client Status</h5>,
    cell: (info) => <ClientStatusToggler candidateDetail={info.row.original} />,
    meta: {
      name: "Client Status",
    },
  }),
  columnHelper.accessor("latestNote", {
    header: () => <h5 className="text-start">Note</h5>,
    cell: (info) => {
      const dispatch = useDispatch();

      const candidateNote = info.getValue();
      return (
        <div className="flex max-w-[200px] items-center justify-between gap-tnano">
          <p className="max-w-[180px] truncate whitespace-nowrap">
            {candidateNote?.note ?? "-"}
          </p>
          {candidateNote && (
            <RhIcon
              data-testid="expanded-view-latest-notes"
              icon="bx:expand-alt"
              className="min-h-[20px] min-w-[20px] cursor-pointer rounded-full bg-gray-100 p-1"
              onClick={() =>
                dispatch(
                  setIsExpandedNoteDailogOpen({
                    ...candidateNote,
                    targetCandidate: info.row.original,
                  }),
                )
              }
            ></RhIcon>
          )}
        </div>
      );
    },
    meta: {
      name: "Notes",
    },
  }),
  columnHelper.accessor("experience_start_date", {
    header: () => <h5 className="text-end">Total Experience (y)</h5>,
    cell: (info) => {
      const startDate = info.getValue();
      if (!startDate) return <p className="text-end">-</p>;

      const formatted = formatExperienceFromStartDate(startDate);
      return (
        <p className="text-end">{`${formatted.years}.${formatted.months}`}</p>
      );
    },
    meta: {
      name: "Total Experience",
    },
  }),
  columnHelper.accessor("is_currently_working", {
    header: () => <h5 className="text-start">Availability</h5>,
    cell: (info) => (
      <p className="whitespace-nowrap text-start">
        {getCandidateJoinStatus(info.row.original)}
      </p>
    ),
    meta: {
      name: "Availability",
    },
  }),
  columnHelper.accessor("buy_out", {
    header: () => <h5 className="text-start">Buyout</h5>,
    cell: (info) => <p className="text-start">{info.getValue()}</p>,
    meta: {
      name: "Buyout",
    },
  }),
  columnHelper.accessor("current_ctc", {
    header: () => <h5 className="text-end">Current CTC (lpa)</h5>,
    cell: (info) => (
      <p className="text-end">{formatCurrency(info.getValue())}</p>
    ),
    meta: {
      name: "Current CTC",
    },
  }),
  columnHelper.accessor("expected_ctc", {
    header: () => <h5 className="text-end">Expected CTC (lpa)</h5>,
    cell: (info) => (
      <p className="text-end">{formatCurrency(info.getValue())}</p>
    ),
    meta: {
      name: "Expected CTC",
    },
  }),
  columnHelper.accessor("offer_in_hand_ctc", {
    header: () => <h5 className="text-end">Current Offer (lpa)</h5>,
    cell: (info) => (
      <p className="text-end">{formatCurrency(info.getValue())}</p>
    ),
    meta: {
      name: "Current Offer",
    },
  }),
  columnHelper.accessor("oih_joining_date", {
    header: () => <h5 className="text-end">Date of Joining</h5>,
    cell: (info) => (
      <p className="text-end">
        {info.getValue() ? dayjs(info.getValue()).format("DD MMM YYYY") : "-"}
      </p>
    ),
    meta: {
      name: "Date of Joining",
    },
  }),
  columnHelper.accessor("preferred_location_new", {
    header: () => <h5 className="text-start">Preferred City</h5>,

    cell: (info) => <PreferredCityView cities={info.getValue()} />,
    meta: {
      name: "Preferred City",
    },
  }),
  columnHelper.accessor("job_role", {
    header: () => <h5 className="text-start">Job Role</h5>,
    cell: (info) => <JobRoleSelect candidate={info.row.original} />,
    meta: {
      name: "Job Role",
    },
  }),
  columnHelper.accessor("email", {
    header: () => <h5 className="text-start">Email</h5>,
    cell: (info) => <p>{info.getValue() ?? "-"}</p>,
    meta: {
      name: "Email",
    },
  }),
  columnHelper.accessor("mobile", {
    header: () => <h5 className="text-end">Primary Phone Number</h5>,
    cell: (info) => {
      if (!info.getValue()) return <p className="text-end">-</p>;

      return (
        <p className="text-end">{`${
          info.row.original.mobile_code ?? ""
        } ${info.getValue()}`}</p>
      );
    },
    meta: {
      name: "Primary Phone Number",
    },
  }),
  columnHelper.accessor("gender", {
    header: () => <h5 className="text-start">Gender</h5>,
    cell: (info) => (
      <p className="text-start">
        {info.getValue ? getGender(info.getValue()) : "-"}
      </p>
    ),
    meta: {
      name: "Gender",
    },
  }),
  columnHelper.accessor("tags", {
    header: () => <h5 className="text-start">Tags</h5>,
    cell: (info) => <TagChipView tags={info.getValue()} />,
    meta: {
      name: "Tags",
    },
  }),
  columnHelper.accessor("candidate_status", {
    header: () => <h5 className="text-start">Candidate Status</h5>,
    cell: (info) => (
      <CandidateStatusToggler candidate={info.row.original} isViewTableCell />
    ),
    meta: {
      name: "Candidate Status",
    },
  }),
  columnHelper.accessor("alt_mobile", {
    header: () => <h5 className="text-end">Alternate Phone Number</h5>,
    cell: (info) => {
      if (!info.getValue()) return <p className="text-end">-</p>;
      return (
        <p className="text-end">{`${info.row.original.alt_mobile_code ?? ""} ${
          info.getValue() ?? "-"
        }`}</p>
      );
    },
    meta: {
      name: "Alternate Phone Number",
    },
  }),
  columnHelper.accessor("candidate_stage", {
    header: () => <h5 className="text-start">Stage</h5>,
    cell: (info) => {
      return (
        <p className="text-start">
          {info.row.original.candidate_stage === "screening" && "Screening"}
          {info.row.original.candidate_stage === "interview" && "Interview"}
          {info.row.original.candidate_stage === "post_interview" &&
            "Post Interview"}
          {!info.row.original.candidate_stage && "-"}
        </p>
      );
    },
    meta: {
      name: "Stage",
    },
  }),
  columnHelper.accessor("recruiter_source", {
    header: () => <h5 className="text-start">Sourced From</h5>,
    cell: (info) => <p className="text-start">{info.getValue() ?? "-"}</p>,
    meta: {
      name: "Sourced From",
    },
  }),
  columnHelper.accessor("assessment_types", {
    header: () => <h5 className="text-start">Z Interviewed</h5>,
    cell: (info) => <InterviewedBagde interviews={info.getValue()} />,
    meta: {
      name: "Z Interviewed",
    },
  }),
  columnHelper.accessor("candidate_primary_skills", {
    header: () => <h5 className="text-start">Primary Skills</h5>,
    cell: (info) => (
      <div className="w-[300px]">
        <PrimarySkillChipView
          candidateDetail={info.row.original}
          hideLabel
          showTooltipOnOverflow
        />
      </div>
    ),
    meta: {
      name: "Primary Skills",
    },
  }),
  columnHelper.accessor("other_skills", {
    header: () => <h5 className="text-start">Secondary Skills</h5>,
    cell: (info) => <OtherSkillsView skills={info.getValue()} />,
    meta: {
      name: "Secondary Skills",
    },
  }),

  columnHelper.accessor("open_to_offer", {
    header: () => <h5 className="text-start">Job Search Status</h5>,
    cell: (info) => (
      <CandidateJobStatusSearchPopover
        candidateDetail={info.row.original}
        isViewTableCell
      />
    ),
    meta: {
      name: "Job Search Status",
    },
  }),
  columnHelper.accessor("location_preference_new", {
    header: () => <h5 className="text-start">Location Preference</h5>,
    cell: (info) => (
      <p className="text-start">
        {info.getValue() && info.getValue()?.length !== 0
          ? info.getValue()?.join(", ")
          : "-"}
      </p>
    ),
    meta: {
      name: "Location Preference",
    },
  }),
  columnHelper.accessor("modified_ts", {
    header: () => <h5 className="text-end">Profile Last Updated</h5>,
    cell: (info) => (
      <p className="text-end">{dayjs(info.getValue()).format("DD MMM YYYY")}</p>
    ),
    meta: {
      name: "Profile Last Updated",
    },
  }),
  columnHelper.accessor("id", {
    header: () => <h5 className="text-start">Actions</h5>,
    meta: {
      name: "Action",
    },
  }),
];

export const allTableColumnKeys: string[] = defaultColumn.map((item) => {
  if (typeof item?.accessorKey === "string") {
    return item.accessorKey;
  }
});

export default defaultColumn;

const JobRoleSelect = ({ candidate }: { candidate: CandidateType }) => {
  const queryClient = useQueryClient();

  const patchCandidate = useMutation({
    mutationFn: (formData: CandidateFormType) => {
      return api.candidate.patchCandidate(
        transformFormDataToCandidateData(formData, ["job_role"]),
      );
    },
    onSuccess() {
      RhToast.success("Candidate job role updated successfully", {
        position: "bottom-right",
      });

      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "LIST"],
      });
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "ITEM", { candidateId: candidate?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATE_ACTIVITIES", { candidateId: candidate?.id }],
      });

      queryClient.invalidateQueries({
        queryKey: [
          "ACTIVITY_LOG_ACTORS",
          {
            candidateId: candidate?.id,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATE_RELEVANT_JOBS", { candidateId: candidate?.id }],
      });
    },
  });

  const loadOptionTimerId = useRef<null | ReturnType<typeof setInterval>>(null);
  const loadOptions = (
    inputValue: string,
    callback: (options: { id: number; name: string }[] | undefined) => void,
  ) => {
    if (loadOptionTimerId.current) clearInterval(loadOptionTimerId.current);
    loadOptionTimerId.current = setTimeout(async () => {
      const res = await queryClient.fetchQuery({
        queryFn: () => api.candidate.getRoles({ search: inputValue }),
        queryKey: ["JOB_ROLES", "LIST", { search: inputValue }],
      });
      callback(res?.data?.results ?? []);
    }, 700);
  };

  const onChangeHandler = (value: { id: number; name: string } | null) => {
    if (!value) return;
    patchCandidate.mutate({ job_role: value, id: candidate.id });
  };

  return (
    <RhTooltip title={candidate.job_role?.name}>
      <RhSelect
        instanceId={"job_role_select_in_table_view"} //https://github.com/JedWatson/react-select/issues/2629
        isClearable={false}
        value={candidate.job_role}
        onChange={(value) => onChangeHandler(value)}
        placeholder="Search job role"
        className="min-w-[180px]"
        backspaceRemovesValue={false}
        async
        loadOptions={loadOptions}
        getOptionLabel={(data: any) => data?.name}
        getOptionValue={(data: any) => data?.id}
        defaultOptions={true}
        menuPortalTarget={
          typeof window !== "undefined" &&
          document.querySelector("#theme-provider")
        }
        styles={{
          control: (base) => ({
            ...base,
            border: "none",
            padding: 0,
            margin: 0,
            outline: "none",
            borderRadius: 0,
            height: "20px",
            minHeight: "20px",
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            height: "20px",
          }),
          singleValue: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          container: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          input: (base) => ({
            ...base,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            padding: 0,
            margin: 0,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (
            <RhIcon
              data-testid="job-role-dropdown-table-view"
              icon="tabler:chevron-down"
              className={`h-[24px] w-[24px] text-gray-600`}
            />
          ),
        }}
        menuPosition={"absolute"}
        menuPlacement={"auto"}
        isDisabled={patchCandidate.isLoading}
        // TODO: figure out a way to include loading state for when options are loading
        // isLoading={isJobRoleListLoading}
      />
    </RhTooltip>
  );
};

const TagChipView = ({ tags }: { tags: CandidateTagType[] | null }) => {
  if (!tags || tags.length === 0) return <p className="text-start">-</p>;
  else
    return (
      <div className="flex items-center gap-tnano">
        {tags?.slice(0, 2).map((candidateTag) => {
          return (
            <Chip
              key={candidateTag.id}
              className="gap-tnano border-none"
              variant={"blueFilled"}
              size={"sm"}
            >
              <p className="whitespace-nowrap text-primary-600">
                {candidateTag.tag.name}
              </p>
            </Chip>
          );
        })}

        {tags?.length > 2 && (
          <BoxTooltip.Root>
            <BoxTooltip.Target>
              <Chip size={"sm"} className="text-hint">
                +{tags.length - 2}
              </Chip>
            </BoxTooltip.Target>
            <BoxTooltip.Content>
              <p className="text-hint px-tsm py-tpico">Tags</p>
              <div className="mx-tsm flex max-w-[350px] flex-wrap items-center gap-tnano">
                {tags.slice(2).map((candidateTag) => (
                  <Chip
                    key={candidateTag.id}
                    className="gap-tnano border-none"
                    variant={"blueFilled"}
                  >
                    <p className="text-primary-600">{candidateTag.tag.name}</p>
                  </Chip>
                ))}
              </div>
            </BoxTooltip.Content>
          </BoxTooltip.Root>
        )}
      </div>
    );
};

const PreferredCityView = ({ cities }: { cities: CityType[] }) => {
  if (!cities || cities.length === 0) return <p>-</p>;
  else
    return (
      <div className="flex items-center gap-tnano">
        <p className="whitespace-nowrap">
          {cities
            ?.slice(0, 2)
            .map((item) => item.name)
            .join(", ")}
        </p>

        {cities?.length > 2 && (
          <BoxTooltip.Root>
            <BoxTooltip.Target>
              <Chip size={"sm"} className="text-hint">
                +{cities.length - 2}
              </Chip>
            </BoxTooltip.Target>
            <BoxTooltip.Content>
              <p className="text-hint px-tsm py-tpico">Preferred City</p>
              <div className="mx-tsm flex max-w-[350px] flex-wrap items-center gap-tnano">
                {cities.slice(2).map((city) => (
                  <Chip
                    key={city.id}
                    className="gap-tnano border-none"
                    variant={"blueFilled"}
                  >
                    <p className="body-small text-gray-900">{city.name}</p>
                  </Chip>
                ))}
              </div>
            </BoxTooltip.Content>
          </BoxTooltip.Root>
        )}
      </div>
    );
};

const InterviewedBagde = ({
  interviews,
}: {
  interviews: CandidateZelevateAssessedType[] | null;
}) => {
  if (!interviews || interviews.length === 0)
    return <p className="text-start">-</p>;
  else
    return (
      <div className="flex items-center gap-tnano">
        {interviews?.slice(0, 2).map((interview) => {
          return (
            <Chip
              key={interview.id}
              className="gap-tnano border-none"
              variant={"blueFilled"}
              // size={"sm"}
            >
              <p className="body-small whitespace-nowrap text-gray-900">
                {interview.name}
              </p>
            </Chip>
          );
        })}

        {interviews.length > 2 && (
          <BoxTooltip.Root>
            <BoxTooltip.Target>
              <Chip size={"sm"} className="text-hint">
                +{interviews.length - 2}
              </Chip>
            </BoxTooltip.Target>
            <BoxTooltip.Content>
              <p className="text-hint px-tsm py-tpico">Z Interviewed</p>
              <div className="mx-tsm flex max-w-[350px] flex-wrap items-center gap-tnano">
                {interviews.slice(2).map((interview) => (
                  <Chip
                    key={interview.id}
                    className="gap-tnano border-none"
                    variant={"blueFilled"}
                  >
                    <p className="body-small text-gray-900">{interview.name}</p>
                  </Chip>
                ))}
              </div>
            </BoxTooltip.Content>
          </BoxTooltip.Root>
        )}
      </div>
    );
};

const OtherSkillsView = ({ skills }: { skills: SkillType[] }) => {
  return (
    <div className="w-[200px]">
      <>
        {skills && skills.length > 0 ? (
          <div className="flex items-center gap-tnano">
            <p>
              {skills
                .slice(0, 2)
                .map((item) => item.name)
                .join(", ")}
            </p>
            {skills.length > 2 && (
              <BoxTooltip.Root>
                <BoxTooltip.Target>
                  <Chip size={"sm"} className="text-hint">
                    +{skills.length - 2}
                  </Chip>
                </BoxTooltip.Target>

                <BoxTooltip.Content>
                  <p className="text-hint px-tsm py-tpico">Secondary Skills</p>
                  <div className="flex h-min flex-wrap gap-tnano px-tsm py-tpico">
                    {skills
                      .slice(2)
                      .map((item) => item.name)
                      .join(", ")}
                  </div>
                </BoxTooltip.Content>
              </BoxTooltip.Root>
            )}
          </div>
        ) : (
          <p>-</p>
        )}
      </>
    </div>
  );
};
