import { createAction, createSlice } from "@reduxjs/toolkit";

import { JobFilterParamType } from "@/types/job";

const defaultFilters: JobFilterParamType = {
  ordering: "-modified_ts",
  status: "Active",
};

const updateFilter = createAction<
  { fitlerName: string; filterValue: string },
  "updateFilter"
>("updateFilter");

export const setJobFilter = createAction<JobFilterParamType, "setFilter">(
  "setFilter"
);

const jobFilterSlice = createSlice({
  name: "jobFilter",
  initialState: defaultFilters,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateFilter, (state, { payload }) => {
        return {
          ...state,
          [payload.fitlerName]: payload.filterValue,
        };
      })
      .addCase(setJobFilter, (state, { payload }) => {
        return payload;
      });
  },
});

export default jobFilterSlice.reducer;
