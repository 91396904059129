import { createAction, createSlice } from "@reduxjs/toolkit";

import { SavedSearchType } from "@/types/candidate";

type valueType = null | SavedSearchType;

export const setCurrentlyAppliedCandidateSavedSearch = createAction<
  valueType,
  "setCurrentlyAppliedCandidateSavedSearch"
>("setCurrentlyAppliedCandidateSavedSearch");

const currentlyAppliedCandidateSavedSearchSlice = createSlice({
  name: "currentlyAppliedCandidateSavedSearch",
  initialState: null as valueType,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      setCurrentlyAppliedCandidateSavedSearch,
      (state, { payload }) => {
        return payload;
      }
    );
  },
});

export default currentlyAppliedCandidateSavedSearchSlice.reducer;
