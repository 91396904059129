import { COOKIE } from "@/constants";
import { deleteCookie } from "cookies-next";
import getDomain from "./getDomain";

const deleteSiteCookiesOnClient = () => {
  const domain = getDomain();

  deleteCookie(COOKIE.ZELREFRESH, { domain, path: "/" });
  deleteCookie(COOKIE.ZELACCESS, { domain, path: "/" });
  deleteCookie(COOKIE.ROLES, { domain, path: "/" });
};

export default deleteSiteCookiesOnClient;
