import { createAction, createSlice } from "@reduxjs/toolkit";

//TODO: These shouldn't be required to have a single dialog open
type TClientStatusDialogActionPayload = {
  id: number;
  open: boolean;
  candidate_id: number;
};
type TDialogOpenState =
  | TClientStatusDialogActionPayload
  | Record<string, never>;

type DialogState = {
  isNotShortlistedInReviewDialogOpen: TDialogOpenState;
  isDroppedInPracticalStageDialogOpen: TDialogOpenState;
  isDroppedInTechnicalStageDialogOpen: TDialogOpenState;
  isDroppedInCulturalStageDialogOpen: TDialogOpenState;
  isOfferRejectedDialogOpen: TDialogOpenState;
  isJoinedDialogOpen: TDialogOpenState;
  isDidNotJoinDialogOpen: TDialogOpenState;

  isCulturalDiscussionRejectDialogOpen: TDialogOpenState;
  isCulturalDiscussionScheduledDialogOpen: TDialogOpenState;
  isPracticalDiscussionRejectDialogOpen: TDialogOpenState;
  isPracticalDiscussionScheduledDialogOpen: TDialogOpenState;
  isTechnicalDiscussionRejectedDialogOpen: TDialogOpenState;
  isTechnicalDiscussionScheduledDialogOpen: TDialogOpenState;
};

const initialState: DialogState = {
  isNotShortlistedInReviewDialogOpen: {},
  isDroppedInPracticalStageDialogOpen: {},
  isDroppedInTechnicalStageDialogOpen: {},
  isDroppedInCulturalStageDialogOpen: {},
  isOfferRejectedDialogOpen: {},
  isJoinedDialogOpen: {},
  isDidNotJoinDialogOpen: {},

  isCulturalDiscussionRejectDialogOpen: {},
  isCulturalDiscussionScheduledDialogOpen: {},
  isPracticalDiscussionRejectDialogOpen: {},
  isPracticalDiscussionScheduledDialogOpen: {},
  isTechnicalDiscussionRejectedDialogOpen: {},
  isTechnicalDiscussionScheduledDialogOpen: {},
};

export const setIsNotShortlistedInReviewDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsNotShortlistedInReviewDialogOpen"
>("setIsNotShortlistedInReviewDialogOpen");

export const setIsDroppedInPracticalStageDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsDroppedInPracticalStageDialogOpen"
>("setIsDroppedInPracticalStageDialogOpen");

export const setIsDroppedInTechnicalStageDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsDroppedInTechnicalStageDialogOpen"
>("setIsDroppedInTechnicalStageDialogOpen");

export const setIsDroppedInCulturalStageDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsDroppedInCulturalStageDialogOpen"
>("setIsDroppedInCulturalStageDialogOpen");

export const setIsOfferRejectedDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsOfferRejectedDialogOpen"
>("setIsOfferRejectedDialogOpen");

export const setIsJoinedDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsJoinedDialogOpen"
>("setIsJoinedDialogOpen");

export const setIsDidNotJoinDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsDidNotJoinDialogOpen"
>("setIsDidNotJoinDialogOpen");

// ------------------------------
export const setIsCulturalDiscussionRejectDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsCulturalDiscussionRejectDialogOpen"
>("setIsCulturalDiscussionRejectDialogOpen");

export const setIsCulturalDiscussionScheduledDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsCulturalDiscussionScheduledDialogOpen"
>("setIsCulturalDiscussionScheduledDialogOpen");

export const setIsPracticalDiscussionRejectdDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsPracticalDiscussionRejectdDialogOpen"
>("setIsPracticalDiscussionRejectdDialogOpen");

export const setIsPracticalDiscussionScheduledDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsPracticalDiscussionScheduledDialogOpen"
>("setIsPracticalDiscussionScheduledDialogOpen");

export const setIsTechnicalDiscussionRejectDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsTechnicalDiscussionRejectDialogOpen"
>("setIsTechnicalDiscussionRejectDialogOpen");

export const setIsTechnicalDiscussionScheduledDialogOpen = createAction<
  TClientStatusDialogActionPayload,
  "setIsTechnicalDiscussionScheduledDialogOpen"
>("setIsTechnicalDiscussionScheduledDialogOpen");

const stageAndStatusPopoverForCandidateOpportunitiesSlice = createSlice({
  name: "stageAndStatusPopoverForCandidateOpportunities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setIsNotShortlistedInReviewDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isNotShortlistedInReviewDialogOpen: {
            ...state.isNotShortlistedInReviewDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsDroppedInPracticalStageDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isDroppedInPracticalStageDialogOpen: {
            ...state.isDroppedInPracticalStageDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsDroppedInTechnicalStageDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isDroppedInTechnicalStageDialogOpen: {
            ...state.isDroppedInTechnicalStageDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsDroppedInCulturalStageDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isDroppedInCulturalStageDialogOpen: {
            ...state.isDroppedInCulturalStageDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(setIsOfferRejectedDialogOpen, (state, { payload }) => {
      return {
        ...state,
        isOfferRejectedDialogOpen: {
          ...state.isOfferRejectedDialogOpen,
          ...payload,
        },
      };
    });
    builder.addCase(setIsJoinedDialogOpen, (state, { payload }) => {
      return {
        ...state,
        isJoinedDialogOpen: {
          ...state.isJoinedDialogOpen,
          ...payload,
        },
      };
    });
    builder.addCase(setIsDidNotJoinDialogOpen, (state, { payload }) => {
      return {
        ...state,
        isDidNotJoinDialogOpen: {
          ...state.isDidNotJoinDialogOpen,
          ...payload,
        },
      };
    });
    builder.addCase(
      setIsCulturalDiscussionRejectDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isCulturalDiscussionRejectDialogOpen: {
            ...state.isCulturalDiscussionRejectDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsCulturalDiscussionScheduledDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isCulturalDiscussionScheduledDialogOpen: {
            ...state.isCulturalDiscussionScheduledDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsPracticalDiscussionRejectdDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isPracticalDiscussionRejectDialogOpen: {
            ...state.isPracticalDiscussionRejectDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsPracticalDiscussionScheduledDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isPracticalDiscussionScheduledDialogOpen: {
            ...state.isPracticalDiscussionScheduledDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsTechnicalDiscussionRejectDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isTechnicalDiscussionRejectedDialogOpen: {
            ...state.isTechnicalDiscussionRejectedDialogOpen,
            ...payload,
          },
        };
      },
    );
    builder.addCase(
      setIsTechnicalDiscussionScheduledDialogOpen,
      (state, { payload }) => {
        return {
          ...state,
          isTechnicalDiscussionScheduledDialogOpen: {
            ...state.isTechnicalDiscussionScheduledDialogOpen,
            ...payload,
          },
        };
      },
    );
  },
});

export default stageAndStatusPopoverForCandidateOpportunitiesSlice.reducer;
