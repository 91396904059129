import { createAction, createSlice } from "@reduxjs/toolkit";

type valueType = boolean;

export const setIsPrimaryReportSetterDialogOpen = createAction<
  valueType,
  "setIsPrimaryReportSetterDialogOpen"
>("setIsPrimaryReportSetterDialogOpen");

const isPrimaryReportSetterDialogOpenSlice = createSlice({
  name: "isPrimaryReportSetterDialogOpen",
  initialState: false,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      setIsPrimaryReportSetterDialogOpen,
      (state, { payload }) => {
        return payload;
      }
    );
  },
});

export default isPrimaryReportSetterDialogOpenSlice.reducer;
