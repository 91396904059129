import { useQuery } from "@tanstack/react-query";

import api from "../api";

export const useGlobalJobStages = () => {
  return useQuery({
    queryFn: api.recruiter.getGlobalJobApplicationStatuses,
    queryKey: ["GLOBAL_JOB_STAGES"],
    select: (res) => res.data,
  });
};

export const useJobRequirementStatuses = ({ jobId }: { jobId: any }) => {
  return useQuery({
    queryKey: ["JOB_REQUIREMENT_STATUS", { jobId }],
    queryFn: () =>
      typeof jobId !== "number"
        ? Promise.reject(new Error("Invalid jobId"))
        : api.company.getJobRequirementStatus({ job_requirement_id: jobId }),
    enabled: typeof jobId === "number",
    select: (res) => {
      const status = {
        ...res.data.data,
      };

      const toReviewStatus = status.submission_status.find(
        (item) => item.status === "To Review"
      );
      if (toReviewStatus) {
        // this status is named differently in recruiter view, so changing this here
        toReviewStatus.status = "Submitted To Review";
      }

      return status;
    },
  });
};
