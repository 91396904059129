import axios, { AxiosResponse } from "axios";
import { BASE_URL, COOKIE } from "../../constants";

import { getCookie, hasCookie, setCookie } from "cookies-next";
import getDomain from "@/helpers/getDomain";
import deleteSiteCookiesOnClient from "@/helpers/deleteSiteCookiesOnSite";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = BASE_URL;

if (typeof window === "object") {
  // attach custom interceptor only when axios used in "client side"
  axiosInstance.interceptors.request.use(
    (config) => {
      if (hasCookie(COOKIE.ZELACCESS) && config.baseURL!.includes(BASE_URL!)) {
        config.headers!.Authorization = `Bearer ${getCookie(COOKIE.ZELACCESS)}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let flag = false;
      let requestArray: {
        originalreq: any;
        resolve: (value: unknown) => void;
        reject: (reason?: string | object) => void;
      }[] = [];
      // handle access token expire
      if (error.response && error.response.status === 401) {
        let tokenResponse: AxiosResponse<any, any>;
        if (flag) {
          return new Promise((resolve, reject) => {
            requestArray.push({
              originalreq: error.response.config,
              resolve,
              reject,
            });
          });
        } else {
          flag = true;
          const refreshToken = getCookie(COOKIE.ZELREFRESH);
          const payload = { refresh: refreshToken };
          try {
            tokenResponse = await axios.post(
              BASE_URL + "/token/refresh/",
              payload
            );

            const domain = getDomain();

            setCookie(COOKIE.ZELACCESS, tokenResponse.data.access, {
              domain,
              path: "/",
              sameSite: "strict",
            });

            if (requestArray.length) {
              requestArray.forEach((req) => {
                req.originalreq.headers.Authorization = `Bearer ${tokenResponse.data.access}`;
                req.resolve(axios(req.originalreq));
              });
            }
            error.response.config.headers.Authorization = `Bearer ${getCookie(
              COOKIE.ZELACCESS
            )}`;
            requestArray = [];
            flag = false;
          } catch (error) {
            if (
              axios.isAxiosError(error) &&
              [400, 401, 403].includes(error.response!.status)
            ) {
              deleteSiteCookiesOnClient();
              return error;
            }
          }

          return axios(error.response.config);
        }
      }

      return Promise.reject(error);
    }
  );
}

export default axiosInstance;
// reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
