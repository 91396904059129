import { createAction, createSlice } from "@reduxjs/toolkit";

export const setBulkSelectedCandidates = createAction<
  number[],
  "setBulkSelectedCandidates"
>("setBulkSelectedCandidates");

export const toggleCandidateSelectedFromBulkSelectedCandidates = createAction<
  number,
  "toggleCandidateSelectedFromBulkSelectedCandidates"
>("toggleCandidateSelectedFromBulkSelectedCandidates");

const bulkSelectedCandidatesSlice = createSlice({
  name: "bulkSelectedCandidates",
  initialState: [] as number[],
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setBulkSelectedCandidates, (state, { payload }) => {
        return payload;
      })

      .addCase(
        toggleCandidateSelectedFromBulkSelectedCandidates,
        (state, { payload }) => {
          const candidateIndexIfAlreadySelected = state.findIndex(
            (candidateId) => candidateId === payload
          );

          const isCandidateAlreadySelected =
            candidateIndexIfAlreadySelected !== -1;

          if (isCandidateAlreadySelected) {
            state.splice(candidateIndexIfAlreadySelected, 1);
          } else {
            state.push(payload);
          }
        }
      );
  },
});

export default bulkSelectedCandidatesSlice.reducer;
