import {
  RhIcon,
  RhListContainer,
  RhLoader,
  RhPopover,
  RhPopoverMenu,
  RhPopoverToggle,
  RhToast,
} from "@rhythm-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";

import { CandidateType, OpenToOffersValuesEnum } from "@/types/candidate";

import api from "@/services/api";

import getCandidateJobSearchStatus from "@/helpers/getCandidateJobSearchStatus";

const CandidateJobStatusSearchPopover = ({
  candidateDetail,
  isViewTableCell = false,
}: {
  candidateDetail: CandidateType | undefined;
  isViewTableCell?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const candidateJobSearchStatus = getCandidateJobSearchStatus(
    candidateDetail?.open_to_offer
  );

  const queryClient = useQueryClient();

  const patchCandidate = useMutation({
    mutationFn: (value: OpenToOffersValuesEnum) => {
      return api.candidate.patchCandidate({
        id: candidateDetail?.id,
        open_to_offer: value,
      });
    },
    onSuccess: () => {
      setIsOpen(false);
      RhToast.success("Candidate job search status changed successfully", {
        position: "bottom-right",
      });

      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "LIST"],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATES",
          "ITEM",
          {
            candidateId: candidateDetail?.id,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATE_ACTIVITIES",
          {
            candidateId: candidateDetail?.id,
          },
        ],
      });
    },
  });

  return (
    <RhPopover
      isOpen={isOpen}
      placement="bottom-start"
      offset={[-12, 10]}
      className="bg-white dark:bg-dark-800"
      onToggle={() => {
        setIsOpen(false);
      }}
    >
      <RhPopoverToggle
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((state) => !state);
        }}
        asChild
      >
        <div
          className={
            !isViewTableCell
              ? "border rounded-[32px] border-primary-300 text-primary-600 py-[2px] px-tnano h-min flex items-center gap-tpico"
              : "flex items-center justify-between"
          }
        >
          <p
            data-testid="job-search-status-candidate-card-btn"
            className="select-none"
          >
            {candidateDetail?.open_to_offer === null
              ? "Job Search Status: Unknown"
              : candidateJobSearchStatus}
          </p>
          {patchCandidate.isLoading ? (
            <RhLoader className=""></RhLoader>
          ) : (
            <>
              {!isViewTableCell ? (
                <RhIcon icon="tabler:chevron-down"></RhIcon>
              ) : (
                <RhIcon
                  data-testid="job-search-status-dropdown"
                  className={`text-gray-600 h-[24px] w-[24px]`}
                  icon="tabler:chevron-down"
                ></RhIcon>
              )}
            </>
          )}
        </div>
      </RhPopoverToggle>
      <RhPopoverMenu className="z-10 bg-white">
        <RhListContainer className="rounded relative w-[340px] overflow-auto shadow-[0_4px_8px_0px_rgba(0,0,0,0.24)] bg-white border-1 border-gray-400">
          <div className={`py-tnano`}>
            <div
              data-testid="actively-looking-option-candidate-card"
              className={`py-tnano px-tsm hover:bg-primary-50 cursor-pointer ${
                candidateDetail?.open_to_offer === "Yes" ? "bg-blue-100" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                patchCandidate.mutate("Yes");
              }}
            >
              <p>Actively Looking</p>
              <div className="mb-tpico" />
              <p className="body-small text-hint">
                Candidate is actively looking for job opportunities and open to
                new offers
              </p>
            </div>

            <div
              data-testid="open-to-consider-option-candidate-card"
              className={`py-tnano px-tsm hover:bg-primary-50 cursor-pointer ${
                candidateDetail?.open_to_offer === "Maybe" ? "bg-blue-100" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                patchCandidate.mutate("Maybe");
              }}
            >
              <p>Open To Consider</p>
              <div className="mb-tpico" />
              <p className="body-small text-hint">
                Candidate is willing to consider job opportunities but not
                actively seeking
              </p>
            </div>

            <div
              data-testid="not-open-option-candidate-card"
              className={`py-tnano px-tsm hover:bg-primary-50 cursor-pointer ${
                candidateDetail?.open_to_offer === "No" ? "bg-blue-100" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                patchCandidate.mutate("No");
              }}
            >
              <p>Not Open To Offers</p>
              <div className="mb-tpico" />
              <p className="body-small text-hint">
                Candidate is currently not interested in new job opportunities
              </p>
            </div>
          </div>
        </RhListContainer>
      </RhPopoverMenu>
    </RhPopover>
  );
};

export default CandidateJobStatusSearchPopover;
