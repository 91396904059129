import { createAction, createSlice } from "@reduxjs/toolkit";

type valueType = { targetCandidateId: number; targetJobId: number } | null;

export const setIsRejectCandidateDialogOpen = createAction<
  valueType,
  "setIsRejectCandidateDialogOpen"
>("setIsRejectCandidateDialogOpen");

const isRejectCandidateDialogOpenSlice = createSlice({
  name: "isRejectCandidateDialogOpen",
  initialState: null as valueType,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setIsRejectCandidateDialogOpen, (state, { payload }) => {
      return payload;
    });
  },
});

export default isRejectCandidateDialogOpenSlice.reducer;
