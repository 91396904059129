import dayjs from "dayjs";

const formatExperienceFromStartDate = (value: string) => {
  const years = dayjs().diff(value, "year");
  const months = dayjs().diff(value, "month") - years * 12;

  return { years, months };
};

export default formatExperienceFromStartDate;
