import { createAction, createSlice } from "@reduxjs/toolkit";

import { CandidateType } from "@/types/candidate";

type ConfirmCandidateBuyInTakenType = null | {
  targetCandidate: CandidateType;
};

type ConfirmCandidateNotInterestedInZelevateType = null | {
  targetCandidate: CandidateType;
};

interface stateType {
  openConfirmCandidateBuyInTakenDialog: ConfirmCandidateBuyInTakenType;
  openConfirmCandidateNotInterestedInZelevateDialog: ConfirmCandidateNotInterestedInZelevateType;
}

const initialState: stateType = {
  openConfirmCandidateBuyInTakenDialog: null,
  openConfirmCandidateNotInterestedInZelevateDialog: null,
};

export const setOpenConfirmCandidateBuyInTakenDialog = createAction<
  ConfirmCandidateBuyInTakenType,
  "setOpenConfirmCandidateBuyInTakenDialog"
>("setOpenConfirmCandidateBuyInTakenDialog");

export const setOpenConfirmCandidateNotInterestedInZelevateDialog =
  createAction<
    ConfirmCandidateNotInterestedInZelevateType,
    "setOpenConfirmCandidateNotInterestedInZelevateDialog"
  >("setOpenConfirmCandidateNotInterestedInZelevateDialog");

const confirmBuyinStatusChangeSlice = createSlice({
  name: "confirmBuyinStatusChange",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        setOpenConfirmCandidateBuyInTakenDialog,
        (state, { payload }) => {
          return {
            ...state,
            openConfirmCandidateBuyInTakenDialog: payload,
            openConfirmCandidateNotInterestedInZelevateDialog: null,
          };
        }
      )
      .addCase(
        setOpenConfirmCandidateNotInterestedInZelevateDialog,
        (state, { payload }) => {
          return {
            ...state,
            openConfirmCandidateBuyInTakenDialog: null,
            openConfirmCandidateNotInterestedInZelevateDialog: payload,
          };
        }
      );
  },
});

export default confirmBuyinStatusChangeSlice.reducer;
