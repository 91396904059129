import { getCookie } from "cookies-next";
import { HYDRATE } from "next-redux-wrapper";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { TInterviewerAvailability } from "@/types/assessment";
import { PaginatedResponse } from "@/types/utils";

import { BASE_URL, COOKIE } from "@/constants";

const interviewerUnavailabilitySlots = `interviewer-unavailability-slots`;
const assessments = "assessments";
//
const assessmentExperienceLevels = `assessment-experience-levels`;
//
//companyTags

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${getCookie(COOKIE.ZELACCESS)}`);
      return headers;
    },
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    // this lets us to run rtk query on server, for SSR pages
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [
    "Unavailability",
    "Jobs",
    "CandidateJobApplication",
    "SelfAppliedList",
  ],

  endpoints: (builder) => ({
    getInterviewerUnavailabilitySlots: builder.query<
      TInterviewerAvailability[],
      {
        candidate_experience_start_date: string | undefined;
        slot_check_start_date: string;
        assessment_type_id: number;
        search: string;
        pagination: false;
      }
    >({
      query: (params) => ({
        url: `${interviewerUnavailabilitySlots}`,
        params,
      }),
      providesTags: ["Unavailability"],
    }),

    postInterview: builder.mutation({
      query: (data) => ({
        url: `${assessments}/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Unavailability"],
    }),
    patchInterview: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${assessments}/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Unavailability"],
    }),

    //
    getAssessmentExperienceLevel: builder.query<
      PaginatedResponse<{ id: number; name: string; level: string }>,
      void
    >({
      query: () => ({
        url: `${assessmentExperienceLevels}`,
      }),
    }),
  }),
});

export const {
  useGetInterviewerUnavailabilitySlotsQuery,
  usePostInterviewMutation,
  usePatchInterviewMutation,

  useGetAssessmentExperienceLevelQuery,
} = api;
