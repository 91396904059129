import { AssessmentFilterParamType, AssessmentType } from "@/types/assessment";
import {
  NewQuestionBankType,
  UpdateAssessmentQuestionType,
  UpdateQuestionBankType,
} from "@/types/assessment-question";
import {
  AssessmentTemplateFilterParamType,
  AssessmentTemplateType,
  AssessmentTemplateUpdateType,
  NewAssessmentTemplateType,
  NewParameterType,
  NewSubParameterType,
  UpdateParameterType,
  UpdateSubParameterType,
} from "@/types/assessment-template";
import { ZelevateReportType, ZelevateReportV3Type } from "@/types/candidate";
import { PaginatedResponse } from "@/types/utils";

import axiosInstance from "@/services/api/axiosInstance";

const assessments = "assessments";
const assessmentTemplates = "assessment-types";

const assessmentDetailsRetrieve = "assessment-type-details";

const assessmentModules = "assessment-modules";
const assessmentSubmodules = "assessment-submodules";
const assessmentQuestions = "assessment-questions";

const assessmentModuleTemplates = "assessment-module-templates";

const interviewAssessmentRecruiterEdit = `interview-assessment-recruiter-edit`;

const difficultyLevel = "difficulty-level";

const interviewmeetingLink = "interview-meeting-link";
const interviewEmailSend = "interview-email-send";
const reports = "candidate-reports";
const pdfReports = "assessment-reports";
const codeEditorLanguages = `code-editor-languages`;
const assessmentExperienceLevels = `assessment-experience-levels`;

//
const interviewCancelReasons = `interview-cancel-reasons`;
const interviewRescheduleReasons = `interview-reschedule-reasons`;

const interviewerUnavailabilitySlots = `interviewer-unavailability-slots`;

export default {
  getAssessments(params: AssessmentFilterParamType) {
    return axiosInstance.get<PaginatedResponse<AssessmentType>>(
      `/${assessments}/`,
      { params },
    );
  },

  patchAssessment(
    id: number | string,
    data: { status?: string; is_primary_report?: boolean },
  ) {
    return axiosInstance.patch(`/${assessments}/${id}/`, data);
  },

  getAssessmentReports(
    candidate_id: number,
    params?: { interview_status?: string },
  ) {
    return axiosInstance.get<{
      data: { interview_recording_list: ZelevateReportType[] };
    }>(`/${reports}/${candidate_id}/`, { params });
  },

  patchAssessmentReports(id: number, data?: any) {
    return axiosInstance.patch<{
      data: { interview_recording_list: ZelevateReportType[] };
    }>(`/${reports}/${id}/`, data);
  },

  patchInterviewAssessmentData(
    id: string,
    data: {
      data: ZelevateReportV3Type;
      modified_obj: {
        id: number;
        old_note: string;
        new_note: string;
        concept_name: string;
      };
    },
  ) {
    return axiosInstance.patch(
      `/${interviewAssessmentRecruiterEdit}/${id}/`,
      data,
    );
  },

  getAssessmentPdfReport(roomId: string) {
    return axiosInstance.get<{
      data: { interview_details: ZelevateReportType };
    }>(`/${pdfReports}/${roomId}/?pdf=true`);
  },

  getAssessmentToken(interviewId: number) {
    return axiosInstance.get(`/${interviewmeetingLink}/`, {
      params: { interview_id: interviewId },
    });
  },

  postInterviewEmailSend(params: { interview_id: number }) {
    return axiosInstance.post(`/${interviewEmailSend}/`, params);
  },

  getEditorLanguages(params: { search: string }) {
    return axiosInstance.get(`/${codeEditorLanguages}/`, { params });
  },

  getAssessmentExperienceLevel(params?: { search?: string }) {
    return axiosInstance.get<
      PaginatedResponse<{
        id: number;
        name: string;
        level: string;
        min_exp: number;
        max_exp: number;
      }>
    >(`/${assessmentExperienceLevels}/`, { params });
  },

  getAssessmentDifficultyLevel() {
    return axiosInstance.get(`/${difficultyLevel}/`);
  },

  // assessment-template
  getAssessmentTemplates(params: AssessmentTemplateFilterParamType) {
    return axiosInstance.get<PaginatedResponse<AssessmentTemplateType>>(
      `/${assessmentTemplates}/`,
      { params },
    );
  },

  getAssessmentTemplate(id: number) {
    return axiosInstance.get<AssessmentTemplateType>(
      `/${assessmentTemplates}/${id}/`,
    );
  },

  postAssessmentTemplate(data: NewAssessmentTemplateType) {
    return axiosInstance.post(`/${assessmentTemplates}/`, data);
  },

  patchAssessmentTemplate(data: AssessmentTemplateUpdateType) {
    const { id, ...payload } = data;
    return axiosInstance.patch(`/${assessmentTemplates}/${id}/`, payload);
  },

  deleteAssessmentTemplate(id: number) {
    return axiosInstance.delete(`/${assessmentTemplates}/${id}/`);
  },

  // assessment-parameter
  getAssessmentParameters(params: {
    assessment_type__id?: number;
    pagination?: boolean;
  }) {
    return axiosInstance.get(`/${assessmentModules}/`, { params });
  },

  postAssessmentParameter(data: NewParameterType | { orderings: number[] }) {
    return axiosInstance.post(`/${assessmentModules}/`, data);
  },

  patchAssessmentParameter(data: UpdateParameterType) {
    const { id, ...payload } = data;
    return axiosInstance.patch(`/${assessmentModules}/${id}/`, payload);
  },

  deleteAssessmentParameter(id: number) {
    return axiosInstance.delete(`/${assessmentModules}/${id}/`);
  },

  // assessment-subparameter

  getAssessmentSubParameters(params: {
    module__assessment_type__id?: number;
    pagination?: boolean;
  }) {
    return axiosInstance.get(`/${assessmentSubmodules}/`, { params });
  },

  postAssessmentSubParameter(
    data: NewSubParameterType | { orderings: number[] },
  ) {
    return axiosInstance.post(`/${assessmentSubmodules}/`, data);
  },

  patchAssessmentSubParameter(data: UpdateSubParameterType) {
    return axiosInstance.patch(`/${assessmentSubmodules}/${data.id}/`, data);
  },

  deleteAssessmentSubParameter(id: number) {
    return axiosInstance.delete(`/${assessmentSubmodules}/${id}/`);
  },

  // assessment-questions
  getAssessmentQuestions(params: any) {
    return axiosInstance.get(`/${assessmentQuestions}/`, { params });
  },
  postAssessmentQuestion(
    data: NewQuestionBankType | { submodule: number; question_ids: number[] },
  ) {
    return axiosInstance.post(`/${assessmentQuestions}/`, data);
  },

  patchAssessmentQuestion(
    data: UpdateAssessmentQuestionType | UpdateQuestionBankType,
  ) {
    const { id, ...payload } = data;
    return axiosInstance.patch(`/${assessmentQuestions}/${id}/`, payload);
  },

  deleteAssessmentQuestions(id: number) {
    return axiosInstance.delete(`/${assessmentQuestions}/${id}`);
  },

  // assessment-details
  getAssessmentDetails(id: number) {
    return axiosInstance.get(`/${assessmentDetailsRetrieve}/${id}/`);
  },

  // assessment-parameter-list
  getAssessmentParameterTemplates(params?: {
    search?: string;
    page?: number;
    ordering?: string;
  }) {
    return axiosInstance.get(`${assessmentModuleTemplates}/`, { params });
  },

  postAssessmentParameterTemplates(data: {
    module_ids?: number[];
    assessment_type?: number;
    isTemplate: boolean;
  }) {
    return axiosInstance.post(`/${assessmentModuleTemplates}/`, data);
  },

  //
  getInterviewCancelReasons() {
    return axiosInstance.get(`${interviewCancelReasons}/`);
  },
  getInterviewRescheduleReasons() {
    return axiosInstance.get(`${interviewRescheduleReasons}/`);
  },

  //
  getInterviewerUnavailabilitySlots(params: {
    candidate_experience_start_date: string | undefined;
    slot_check_start_date: string;
    slot_check_end_date: string;
    assessment_type_id: number;
  }) {
    return axiosInstance.get(`${interviewerUnavailabilitySlots}`, { params });
  },
  uploadCandidateReport(room_id: string, data: string | Blob) {
    const bodyFormData = new FormData();
    bodyFormData.append("interview_report", data);

    return axiosInstance.patch(`interview-report/${room_id}/`, bodyFormData);
  },
};
