import {
  AssessmentConceptFilterParamType,
  AssessmentConceptType,
  AssessmentConceptUpdateType,
  AssessmentQuestionFilterParamType,
  AssessmentTopicFilterParamType,
  AssessmentTopicType,
  AssessmentTopicUpdateType,
  NewAssessmentTopicUpdateType,
} from "@/types/assessment-template";
import { PaginatedResponse } from "@/types/utils";

import axiosInstance from "../axiosInstance";

const assessmentBuilderTopics = "assessment-builder-topics";
const assessmentBuilderConcepts = "assessment-builder-concepts";
const assessmentBuilderQuestions = "assessment-builder-questions";

const assessmentBuilderLinkage = `assessment-builder-linkage`;

export default {
  getAssessmentBuilderTopics(params: AssessmentTopicFilterParamType) {
    return axiosInstance.get<PaginatedResponse<AssessmentTopicType>>(
      `/${assessmentBuilderTopics}/`,
      { params },
    );
  },

  postAssessmentTopic(data: NewAssessmentTopicUpdateType) {
    return axiosInstance.post(`/${assessmentBuilderTopics}/`, data);
  },

  patchAssessmentTopic(data: AssessmentTopicUpdateType) {
    const { id, ...payload } = data;
    return axiosInstance.patch(`/${assessmentBuilderTopics}/${id}/`, payload);
  },

  deleteAssessmentTopic(id: number) {
    return axiosInstance.delete(`/${assessmentBuilderTopics}/${id}/`);
  },

  getAssessmentBuilderConcepts(params: AssessmentConceptFilterParamType) {
    return axiosInstance.get<PaginatedResponse<AssessmentConceptType>>(
      `/${assessmentBuilderConcepts}/`,
      {
        params,
      },
    );
  },
  postAssessmentConcept(data: any) {
    return axiosInstance.post(`/${assessmentBuilderConcepts}/`, data);
  },
  patchAssessmentConcept(data: AssessmentConceptUpdateType) {
    const { id, ...payload } = data;
    return axiosInstance.patch(`/${assessmentBuilderConcepts}/${id}/`, payload);
  },
  deleteAssessmentConcept(id: number) {
    return axiosInstance.delete(`/${assessmentBuilderConcepts}/${id}/`);
  },

  getAssessmentBuilderQuestions(params: AssessmentQuestionFilterParamType) {
    return axiosInstance.get(assessmentBuilderQuestions, {
      params,
    });
  },

  postAssessmentBuilderQuestion(data: any) {
    return axiosInstance.post(`/${assessmentBuilderQuestions}/`, data);
  },

  patchAssessmentBuilderQuestion(data: any) {
    const { id, ...payload } = data;
    return axiosInstance.patch(
      `/${assessmentBuilderQuestions}/${id}/`,
      payload,
    );
  },

  deleteAssessmentBuilderQuestion(id: number) {
    return axiosInstance.delete(`/${assessmentBuilderQuestions}/${id}/`);
  },

  postAssessmentBuilderLinkage(data: any) {
    return axiosInstance.post(`/${assessmentBuilderLinkage}/`, data);
  },

  patchAssessmentBuilderLinkage(data: any) {
    return axiosInstance.patch(`/${assessmentBuilderLinkage}/`, data);
  },

  deleteAssessmentBuilderLinkage(data: any) {
    return axiosInstance.put(`/${assessmentBuilderLinkage}/`, data);
  },
};
