import { createAction, createSlice } from "@reduxjs/toolkit";

import { CandidateType } from "@/types/candidate";

type IssueOfferActionType = null | {
  previousCandidateStatus: string;
  targetCandidate: CandidateType;
};

type RequestCandidateSlotActionType = null | {
  previousCandidateStatus: string;
  currentCandidateStatus: string;
  targetCandidate: CandidateType;
};

interface stateType {
  issueOffer: IssueOfferActionType;
  requestOffer: RequestCandidateSlotActionType;
}

const initialState: stateType = {
  issueOffer: null,
  requestOffer: null,
};

export const setIssueOfferAction = createAction<
  IssueOfferActionType,
  "setIssueOfferAction"
>("setIssueOfferAction");

export const setRequestCandidateSlotAction = createAction<
  RequestCandidateSlotActionType,
  "setRequestCandidateSlotAction"
>("setRequestCandidateSlotAction");

const suggestActionToClientSlice = createSlice({
  name: "suggestActionToClient",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setIssueOfferAction, (state, { payload }) => {
        return {
          ...state,
          issueOffer: payload,
          requestOffer: null,
        };
      })
      .addCase(setRequestCandidateSlotAction, (state, { payload }) => {
        return { ...state, issueOffer: null, requestOffer: payload };
      });
  },
});

export default suggestActionToClientSlice.reducer;
