import dayjs from "dayjs";

import { CandidateType } from "@/types/candidate";

const getCandidateJoinStatus = (candidateDetail: CandidateType | undefined) => {
  if (candidateDetail?.is_currently_working === true) {
    if (candidateDetail.resigned === false) {
      if (candidateDetail.notice_period === null) return "Currently working";
      return `${candidateDetail.notice_period} days - Notice Period ${
        candidateDetail.negotiable_notice_period === true ? "(Negotiable)" : ""
      }`;
    } else {
      if (candidateDetail.last_working_day === null) return "Currently working";
      return doesDateExceedToday(candidateDetail.last_working_day)
        ? "Immediate"
        : `${dayjs(candidateDetail.last_working_day).format(
            "DD MMM YYYY"
          )} - Last Working Date`;
    }
  } else if (candidateDetail?.is_currently_working === false) {
    if (candidateDetail?.is_available_immediately) return "Immediate";
    else if (candidateDetail?.candidate_join_date)
      return doesDateExceedToday(candidateDetail.candidate_join_date)
        ? "Immediate"
        : `Can join from ${dayjs(candidateDetail.candidate_join_date).format(
            "DD MMM YYYY"
          )}`;
    else if (candidateDetail?.candidate_join_date === null) {
      return "Not working currently";
    } else return "unhandled";
  } else return "-";
};

const doesDateExceedToday = (date: string) => {
  const dateAsDayjs = dayjs(date);
  const today = dayjs();
  const isBeforeOrEqual =
    dateAsDayjs.isBefore(today) || dateAsDayjs.isSame(today, "day");
  if (isBeforeOrEqual) return true;
  return false;
};

export default getCandidateJoinStatus;
