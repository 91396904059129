import { RhInputFormik } from "@rhythm-ui/react";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { RootState } from "@/redux/types";

import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";

import { OpportunityContext } from "../constants";
import { setIsTechnicalDiscussionScheduledDialogOpen } from "../stageAndStatusPopoverForCandidateOpportunitiesSlice";
import { FormSubmissionValues } from "../types";

type TDidNotJoinDialogProps = {
  handleSubmit: (values: FormSubmissionValues) => void;
};

const DidNotJoinDialog = (props: TDidNotJoinDialogProps) => {
  const opportunity = useContext(OpportunityContext);
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state: RootState) =>
      state.stageAndStatusPopoverForCandidateOpportunitiesSlice
        .isTechnicalDiscussionScheduledDialogOpen,
  );

  const handleSubmit = (values: FormSubmissionValues) => {
    // Handle form submission logic here
    props.handleSubmit(values);
    handleClose();
  };

  if (
    isOpen.id !== opportunity?.job_id ||
    isOpen.candidate_id !== opportunity.candidate_id
  )
    return null;

  const handleClose = () => {
    dispatch(
      setIsTechnicalDiscussionScheduledDialogOpen({
        id: opportunity?.job_id,
        open: false,
        candidate_id: opportunity.candidate_id,
      }),
    );
  };

  return (
    <Dialog isOpen={isOpen.open} className="w-[600px]">
      <DialogHeader>
        <DialogTitle>Technical discussion schedule</DialogTitle>
        <DialogDescription>
          Mention the data and time for the technical discussion round
        </DialogDescription>
      </DialogHeader>

      <Formik
        initialValues={{ detailed_reason: "" }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          detailed_reason: Yup.string().required("Please provide a comment"),
        })}
      >
        <Form>
          <DialogBody>
            <RhInputFormik
              name="detailed_reason"
              type={"textarea"}
              className="w-full"
              rows={4}
              autoFocus
              placeholder="Enter your comment"
            />
          </DialogBody>

          <DialogFooter>
            <Button variant={"tertiary"} onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogFooter>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default DidNotJoinDialog;
