import { createAction, createSlice } from "@reduxjs/toolkit";

type matchCandidateToJobType = null | {
  targetCandidateId: number;
};

interface stateType {
  openMatchCandidateToJobDialog: matchCandidateToJobType;
}

const initialState: stateType = {
  openMatchCandidateToJobDialog: null,
};

export const setOpenMatchCandidateToJobDialog = createAction<
  matchCandidateToJobType,
  "setOpenMatchCandidateToJobDialog"
>("setOpenMatchCandidateToJobDialog");

const matchCandidateToJobDialogSlice = createSlice({
  name: "matchCandidateToJobDialog",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setOpenMatchCandidateToJobDialog, (state, { payload }) => {
      return {
        ...state,
        openMatchCandidateToJobDialog: payload,
      };
    });
  },
});

export default matchCandidateToJobDialogSlice.reducer;
