import { useRouter } from "next/router";

import MixPanel from "@/MixPanel";

const useTrackEvent = () => {
  const router = useRouter();

  const trackEventFn = (args: {
    name: string;
    properties?: Record<string, any>;
  }) => {
    // mixpanel
    MixPanel.track(args.name, {
      ...(router && { onPage: router.asPath }),
      ...args.properties,
    });
  };
  return trackEventFn;
};

export default useTrackEvent;
