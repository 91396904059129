import { createAction, createSlice } from "@reduxjs/toolkit";

import { EducationType, ExperienceType } from "@/types/candidate";

type DeleteCandidateExperienceType = null | {
  targetExperience: ExperienceType;
};

type DeleteCandidateEducationType = null | {
  targetEducation: EducationType;
};

interface stateType {
  openDeleteCandidateExperienceDialog: DeleteCandidateExperienceType;
  openDeleteCandidateEducationDialog: DeleteCandidateEducationType;
}

const initialState: stateType = {
  openDeleteCandidateExperienceDialog: null,
  openDeleteCandidateEducationDialog: null,
};

export const setOpenDeleteCandidateExperienceDialog = createAction<
  DeleteCandidateExperienceType,
  "setOpenDeleteCandidateExperienceDialog"
>("setOpenDeleteCandidateExperienceDialog");

export const setOpenDeleteCandidateEducationDialog = createAction<
  DeleteCandidateEducationType,
  "setOpenDeleteCandidateEducationDialog"
>("setOpenDeleteCandidateEducationDialog");

const confirmCandidateMutateSlice = createSlice({
  name: "confirmCandidateMutate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setOpenDeleteCandidateExperienceDialog, (state, { payload }) => {
        return {
          ...state,
          openDeleteCandidateExperienceDialog: payload,
          openDeleteCandidateEducationDialog: null,
        };
      })
      .addCase(setOpenDeleteCandidateEducationDialog, (state, { payload }) => {
        return {
          ...state,
          openDeleteCandidateEducationDialog: payload,
          openDeleteCandidateExperienceDialog: null,
        };
      });
  },
});

export default confirmCandidateMutateSlice.reducer;
