import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const debounce = (
  func: (...args: any[]) => Promise<any>,
  wait: number
) => {
  let timeout: ReturnType<typeof setInterval> | undefined;
  return function executedFunction(...args: any[]) {
    const later = () => {
      timeout = undefined;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const openLinkInNewTab = (url: string, name: string) => {
  if (!url.match(/^https?:\/\//i)) {
    url = "http://" + url;
  }
  return window.open(url, name);
};
