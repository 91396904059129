import { createAction, createSlice } from "@reduxjs/toolkit";

type valueType = null | string;

export const setBulkCandidateAction = createAction<
  valueType,
  "setBulkCandidateAction"
>("setBulkCandidateAction");

const bulkCandidateActionSlice = createSlice({
  name: "bulkCandidateAction",
  initialState: null as valueType,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setBulkCandidateAction, (state, { payload }) => {
      return payload;
    });
  },
});

export default bulkCandidateActionSlice.reducer;
