import { createContext } from "react";

export const OpportunityContext = createContext<
  | {
      stage: string;
      status: string;
      interview_id: number | null;
      job_id: number;
      candidate_id: number;
    }
  | undefined
>(undefined);

export const CLIENT_STAGES = {
  REVIEW: "In Review",
  INTERVIEWS: "In Interviews",
  OFFERS: "In Offers",
  JOINING: "In Joining",
};

export const CLIENT_STATUS = {
  "In Review": {
    SHORTLISTED: "Profile Shortlisted",
    ON_HOLD: "Profile On Hold",
    REJECT: "Not Shortlisted",
  },
  "In Interviews": {
    TECHNICAL: {
      TO_SCHEDULE: "To Schedule",
      SCHEDULED: "Scheduled",
      DROPPED: "Dropped",
      REJECTED: "Rejected In Interviews",
      ON_HOLD: "On Hold",
    },
    PRACTICAL: {
      TO_SEND: "To Send",
      SENT: "Assignment Sent",
      COMPLETED: "Assignment Completed",
      DROPPED: "Dropped",
      REJECTED: "Rejected in Assignment",
      ON_HOLD: "On Hold",
    },
    CULTURAL: {
      TO_SCHEDULE: "To Schedule",
      SCHEDULED: "Scheduled",
      COMPLETED: "Completed",
      DROPPED: "Dropped",
      REJECTED: "Rejected In Interviews",
      ON_HOLD: "On Hold",
    },
  },
  "In Offers": {
    BGV: "In BGV",
    TO_OFFER: "To Offer",
    OFFERED: "Offered",
    REJECTED: "Offer Rejected",
    ACCEPTED: "Offer Accepted",
  },
  "In Joining": {
    JOINED: "Joined",
    NOT_JOINED: "Did Not Join",
  },
};

export const NEGATIVE_INTERVIEW_STATUS = [
  CLIENT_STATUS["In Interviews"].CULTURAL.DROPPED,
  CLIENT_STATUS["In Interviews"].CULTURAL.REJECTED,
  CLIENT_STATUS["In Interviews"].PRACTICAL.DROPPED,
  CLIENT_STATUS["In Interviews"].PRACTICAL.REJECTED,
  CLIENT_STATUS["In Interviews"].TECHNICAL.DROPPED,
  CLIENT_STATUS["In Interviews"].TECHNICAL.REJECTED,
];
