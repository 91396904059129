import * as Sentry from "@sentry/nextjs";
import { getCookie } from "cookies-next";

import { RhToast } from "@rhythm-ui/react";
import jwt, { JwtPayload } from "jsonwebtoken";

import { COOKIE } from "@/constants";

const showToastMessages = (error: {
  response?: { data?: { error_list?: string[] } };
}) => {
  const zelAccess = getCookie(COOKIE.ZELACCESS) as string;
  if (error?.response?.data?.error_list) {
    const error_reasons = Object.values(error.response.data.error_list);
    error_reasons.slice(0, 5).forEach((reason) => {
      const fieldName = Object.keys(reason)[0];
      const capitalizedFieldName =
        fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
      const fieldError = Object.values(reason)[0];
      if (zelAccess) {
        const { user_id, email } = jwt.decode(zelAccess) as JwtPayload;
        Sentry.captureMessage(
          ` 🆔-${user_id || "-"} 📧 ${
            email || "-"
          } 📢 ${capitalizedFieldName}: ${fieldError}`,
          {
            level: "debug",
            tags: {
              zelevate: true,
            },
          }
        );
      }

      if (window.innerWidth < 1024) {
        RhToast.error(`${capitalizedFieldName}: ${fieldError}`, {
          position: "top-right",
          className: "top-[56px]",
        });
      } else
        RhToast.error(`${capitalizedFieldName}: ${fieldError}`, {
          position: "bottom-right",
        });
    });
  } else {
    if (zelAccess) {
      const { user_id, email } = jwt.decode(zelAccess) as JwtPayload;
      Sentry.captureMessage(
        `🆔-${user_id || "-"} 📧 ${email || "-"} 📢} Something went wrong`,
        {
          level: "debug",
          tags: {
            zelevate: true,
          },
        }
      );
    }
    // POSTHOG error
    RhToast.error("Something went wrong. We are working on it", {
      position: "bottom-right",
    });
  }
};
export default showToastMessages;
